import { useAuth } from './useAuth'

type Can = 'fields' | 'offices' | 'photos'

type Permission = {
  // null is unlimited
  fields: number | null
  offices: number | null
  photos: number | null
}

type Permissions = {
  [key: string]: Permission
}

const PERMISSIONS: Permissions = {
  free: { fields: 1, offices: 1, photos: 0 },
  silver: { fields: 2, offices: 4, photos: null },
  gold: { fields: null, offices: null, photos: null }
}

export function useCan(can: Can, data?: unknown[]): boolean {
  const { user } = useAuth()
  if (user) {
    const permission = PERMISSIONS[user.plan][can]

    if (permission === null) {
      return true
    } else {
      if (data) {
        return data.length < permission
      } else {
        return true
      }
    }
  }
  return false
}
