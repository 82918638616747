import { useEffect, useState } from 'react'
import { IoStar } from 'react-icons/all'

import { Flex, Icon } from '@chakra-ui/react'

type StarRatingProps = {
  hasClick?: boolean
  userRating: number
}

export function StarRating({
  hasClick,
  userRating
}: StarRatingProps): JSX.Element {
  const [rating, setRating] = useState<number>(0)
  const [hover, setHover] = useState<number>(0)

  useEffect(() => {
    setRating(userRating)
  }, [userRating])

  return (
    <Flex alignItems={'center'} mr={2} h={'min-content'}>
      {[...Array(5)].map((star, i) => {
        const ratingValue = i + 1

        return (
          <label key={i}>
            <input
              type={'radio'}
              style={{ display: 'none' }}
              name={'rating'}
              value={ratingValue}
              onClick={() => hasClick && setRating(ratingValue)}
            />
            <Icon
              as={IoStar}
              color={
                ratingValue <= (hover || rating) ? 'yellow.300' : 'gray.200'
              }
              w={3}
              h={3}
              marginRight={0.5}
              onMouseEnter={() => hasClick && setHover(ratingValue)}
              onMouseLeave={() => hasClick && setHover(0)}
            />
          </label>
        )
      })}
    </Flex>
  )
}
