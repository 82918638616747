import { ChangeEvent, useRef, useState } from 'react'
import { IoClose } from 'react-icons/io5'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'

import {
  Box,
  Flex,
  Icon,
  IconButton,
  Img,
  Input,
  Skeleton,
  Text,
  useToast,
  Button
} from '@chakra-ui/react'
import { AxiosError } from 'axios'
import * as yup from 'yup'

import { useCan } from '../../hooks/useCan'
import { api } from '../../services/api'
import { uploadImage } from '../../utils/uploadImage'
import { ConfirmationModal } from '../ConfirmationModal'
import { ContentWrapper } from '../ContentWrapper'

type Picture = {
  id: string
  filePath: string
}

type UsePictureResult = {
  pictures: Picture[]
}

export function usePictures(
  options?: UseQueryOptions<UsePictureResult, AxiosError>
): UseQueryResult<UsePictureResult, AxiosError> {
  return useQuery<UsePictureResult, AxiosError>(
    ['me-pictures'],
    async () => {
      const { data } = await api.get<Picture[]>(`/lawyer/profile/photos`)

      return { pictures: data }
    },
    {
      staleTime: 1000 * 60 * 1, // One minutes
      ...options
    }
  )
}

export function Pictures(): JSX.Element {
  const { data, isLoading, error, isFetching, refetch } = usePictures()
  const [deletingPicture, setDeletingPicture] = useState('')
  const can = useCan('photos', data?.pictures)
  const inputRef = useRef<HTMLInputElement>(null)
  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: 'top-right'
  })

  async function submitImage(
    event: ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    try {
      const image = event.target.files?.[0]

      const { fileName } = await uploadImage(image)
      await api.post('/lawyer/profile/photos', { filePath: fileName })
      toast({
        status: 'success',
        description: 'Foto adicionada com sucesso'
      })
      refetch()
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        toast({
          status: 'error',
          title: 'Erro de validação',
          description: error.message
        })
      } else {
        toast({
          status: 'error',
          description: 'Erro ao tentar adicionar a foto'
        })
      }
    } finally {
      if (inputRef.current) {
        inputRef.current.value = ''
      }
    }
  }

  function handleClickToOpenInput(): void {
    if (can) {
      inputRef.current?.click()
    } else {
      toast({
        status: 'warning',
        title: 'Você não tem permissão!',
        description: 'Seu plano limita a criação de fotos.'
      })
    }
  }

  async function handleDeletePicture(): Promise<void> {
    try {
      await api.delete(`/lawyer/profile/photos/${deletingPicture}`)
      setDeletingPicture('')
      refetch()
      toast({
        status: 'success',
        description: 'Foto removida com sucesso'
      })
    } catch (error) {
      toast({
        status: 'error',
        description: 'Erro ao tentar excluir a foto'
      })
    }
  }

  return (
    <>
      <ContentWrapper
        isLoading={!isLoading && isFetching}
        w="full"
        title="Fotos"
        infoLabel="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        headerChildren={
          <>
            <Button
              colorScheme="green"
              variant="link"
              onClick={handleClickToOpenInput}
            >
              Adicionar foto
            </Button>

            <Input
              ref={inputRef}
              type="file"
              d="none"
              accept="image/png, image/jpeg, image/jpg"
              onChange={submitImage}
            />
          </>
        }
      >
        <Flex gridGap="6" flexWrap="wrap">
          {error ? (
            <Text color="red.400" fontWeight="medium">
              Falha ao tentar buscar os suas fotos
            </Text>
          ) : isLoading ? (
            <>
              <Box>
                <Skeleton w="100px" h="100px" />
              </Box>
              <Box>
                <Skeleton w="100px" h="100px" />
              </Box>
            </>
          ) : data?.pictures.length === 0 ? (
            <Text fontWeight="medium">Você não possui fotos cadastradas.</Text>
          ) : (
            data?.pictures.map(picture => (
              <Box key={picture.id} position="relative">
                <Img
                  src={picture.filePath}
                  w="100px"
                  h="100px"
                  objectFit="cover"
                  borderRadius="4"
                  alt="foto"
                  loading="lazy"
                />
                <IconButton
                  minW="7"
                  h="7"
                  position="absolute"
                  bottom="-10px"
                  right="-10px"
                  bg="gray.700"
                  color="white"
                  _hover={{ bg: 'gray.600' }}
                  borderRadius="full"
                  icon={<Icon as={IoClose} />}
                  aria-label="Excluir foto"
                  onClick={() => setDeletingPicture(picture.id)}
                />
              </Box>
            ))
          )}
        </Flex>
      </ContentWrapper>
      <ConfirmationModal
        title="Excluir foto?"
        isOpen={!!deletingPicture}
        onAccept={handleDeletePicture}
        onClose={() => setDeletingPicture('')}
      />
    </>
  )
}
