import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'

import { AxiosError, AxiosRequestConfig } from 'axios'

import { api } from '../services/api'

export type PlanSlug = 'free' | 'silver' | 'gold'

export type Plan = {
  slug: PlanSlug
  name: string
  price: number | string
  active?: boolean
}

export type GetPlansResponse = {
  plans: Plan[]
}

export async function getPlans(
  config?: AxiosRequestConfig
): Promise<GetPlansResponse> {
  const { data } = await api.get(`/lawyer/plans`, {
    ...config
  })

  return {
    plans: data
  }
}

export function usePlans(
  options?: UseQueryOptions<GetPlansResponse, AxiosError>
): UseQueryResult<GetPlansResponse, AxiosError> {
  return useQuery<GetPlansResponse, AxiosError>(['plans'], () => getPlans(), {
    staleTime: 1000 * 60 * 5, // Five minutes
    ...options
  })
}
