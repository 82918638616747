import { Redirect, Switch } from 'react-router-dom'

import { useAuth } from '../hooks/useAuth'
import { Assessments } from '../pages/assessments'
import { Forgot } from '../pages/forgot'
import { Home } from '../pages/home'
import { Loading } from '../pages/loading'
import { Me } from '../pages/me'
import { Pin } from '../pages/pin'
import { Plan } from '../pages/plan'
import { Plans } from '../pages/plans'
import { Reset } from '../pages/reset'
import { Settings } from '../pages/Settings'
import { SignIn } from '../pages/signin'
import { SignUp } from '../pages/signup'
import { Route } from './Route'

export function Routes(): JSX.Element {
  const { isAuthenticated, loading } = useAuth()

  if (loading) {
    return <Loading />
  }

  return (
    <Switch>
      <Route path="/signin" component={SignIn} exact />
      <Route path="/signup" component={SignUp} exact />
      <Route path="/forgot" component={Forgot} exact />
      <Route path="/reset" component={Reset} exact />
      <Route path="/pin" isPrivate component={Pin} exact />
      <Route path="/home" isPrivate component={Home} exact />
      <Route path="/plans" isPrivate component={Plans} exact />
      <Route path="/plans/:slug" isPrivate component={Plan} exact />
      <Route path="/me" isPrivate component={Me} exact />
      <Route path="/assessments" isPrivate component={Assessments} exact />
      <Route path="/settings" isPrivate component={Settings} exact />
      {isAuthenticated ? (
        <Redirect to="/home" exact />
      ) : (
        <Redirect to="/signin" exact />
      )}
    </Switch>
  )
}
