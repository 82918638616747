import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'

import { AxiosError, AxiosRequestConfig } from 'axios'

import { api } from '../services/api'
import { Plan, PlanSlug } from './usePlans'

type UsePlanProps = {
  plan: PlanSlug
}

export type GetPlanResponse = {
  plan: Plan
}

export async function getPlans(
  { plan }: UsePlanProps,
  config?: AxiosRequestConfig
): Promise<GetPlanResponse> {
  const { data } = await api.get<Plan>(`/lawyer/plans/${plan}`, {
    ...config
  })

  return {
    plan: data
  }
}

export function usePlan(
  { plan }: UsePlanProps,
  options?: UseQueryOptions<GetPlanResponse, AxiosError>
): UseQueryResult<GetPlanResponse, AxiosError> {
  return useQuery<GetPlanResponse, AxiosError>(
    ['plans', plan],
    () => getPlans({ plan }),
    {
      staleTime: 1000 * 60 * 5, // Five minutes
      ...options
    }
  )
}
