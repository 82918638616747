// eslint-disable-next-line @typescript-eslint/no-var-requires
const pagarme = require('pagarme')

type Card = {
  card_number: string
  card_holder_name: string
  card_expiration_date: string
  card_cvv: string
}

type Client = {
  security: {
    encrypt: (card: Card) => string
  }
}

export async function encryptCard(card: Card): Promise<string> {
  return new Promise(resolve => {
    pagarme.client
      .connect({ encryption_key: process.env.REACT_APP_PAGARME_ENCRIPTION_KEY })
      .then((client: Client) => {
        return client.security.encrypt(card)
      })
      .then((card_hash: string) => resolve(card_hash))
  })
}
