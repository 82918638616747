import { ChangeEvent, useRef, useState } from 'react'
import { IoClose } from 'react-icons/io5'
import { MdVerifiedUser } from 'react-icons/md'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'

import {
  Text,
  VStack,
  Stack,
  Icon,
  Box,
  Skeleton,
  IconButton,
  useToast,
  Img,
  Flex,
  Input
} from '@chakra-ui/react'
import { AxiosError } from 'axios'
import * as yup from 'yup'

import { api } from '../../../services/api'
import { uploadImage } from '../../../utils/uploadImage'
import { ConfirmationModal } from '../../ConfirmationModal'

type Certificate = {
  id: string
  filePath: string
}

type UseCertificatesResult = {
  certificates: Certificate[]
}

export function useCertificates(
  options?: UseQueryOptions<UseCertificatesResult, AxiosError>
): UseQueryResult<UseCertificatesResult, AxiosError> {
  return useQuery<UseCertificatesResult, AxiosError>(
    ['me-certificates'],
    async () => {
      const { data } = await api.get<Certificate[]>(
        `/lawyer/profile/certificates`
      )

      return { certificates: data }
    },
    {
      staleTime: 1000 * 60 * 1, // One minutes
      ...options
    }
  )
}

export function Certificates(): JSX.Element {
  const { data, isLoading, error, refetch } = useCertificates()
  const [deletingCertificate, setDeletingCertificate] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)
  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: 'top-right'
  })

  async function submitImage(
    event: ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    try {
      const image = event.target.files?.[0]

      const { fileName } = await uploadImage(image)
      await api.post('/lawyer/profile/certificates', { filePath: fileName })
      toast({
        status: 'success',
        description: 'Certificado adicionado com sucesso'
      })
      refetch()
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        toast({
          status: 'error',
          title: 'Erro de validação',
          description: error.message
        })
      } else {
        toast({
          status: 'error',
          description: 'Erro ao tentar adicionar a foto'
        })
      }
    } finally {
      if (inputRef.current) {
        inputRef.current.value = ''
      }
    }
  }

  async function handleDeleteCertificate(): Promise<void> {
    try {
      await api.delete(`/lawyer/profile/certificates/${deletingCertificate}`)
      setDeletingCertificate('')
      refetch()
      toast({
        status: 'success',
        description: 'Certificado removido com sucesso'
      })
    } catch (error) {
      toast({
        status: 'error',
        description: 'Erro ao tentar excluir o certificado'
      })
    }
  }

  return (
    <>
      <VStack gridGap="6" flexWrap="wrap" mt="4">
        <Stack w="full" direction={['column', 'column', 'row']}>
          <Icon as={MdVerifiedUser} color="green.300" w="7" h="7" mt="2" />
          <Box w="full">
            <Stack
              w="full"
              justify="space-between"
              direction={['column', 'column', 'row']}
            >
              <Text fontWeight="600" fontSize="xl">
                Certificados
              </Text>
              <Box>
                <Text
                  as="label"
                  color="green.300"
                  mr="2"
                  fontWeight="bold"
                  cursor="pointer"
                  _hover={{ textDecoration: 'underline' }}
                >
                  Adicionar certificado
                  <Input
                    ref={inputRef}
                    type="file"
                    d="none"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={submitImage}
                  />
                </Text>
              </Box>
            </Stack>
            <Flex gridGap="6" flexWrap="wrap" mt="2">
              {error ? (
                <Text color="red.400" fontWeight="medium">
                  Falha ao tentar buscar os seus certificados
                </Text>
              ) : isLoading ? (
                <>
                  <Box>
                    <Skeleton w="100px" h="100px" />
                  </Box>
                  <Box>
                    <Skeleton w="100px" h="100px" />
                  </Box>
                </>
              ) : data?.certificates.length === 0 ? (
                <Text fontWeight="medium">
                  Você não possui certificados cadastrados.
                </Text>
              ) : (
                data?.certificates.map(certificate => (
                  <Box key={certificate.id} position="relative">
                    <Img
                      src={certificate.filePath}
                      w="100px"
                      h="100px"
                      objectFit="cover"
                      borderRadius="4"
                      alt="certificado"
                      loading="lazy"
                    />
                    <IconButton
                      minW="7"
                      h="7"
                      position="absolute"
                      bottom="-10px"
                      right="-10px"
                      borderRadius="full"
                      bg="gray.700"
                      color="white"
                      _hover={{ bg: 'gray.600' }}
                      aria-label="Excluir foto"
                      icon={<Icon as={IoClose} />}
                      onClick={() => setDeletingCertificate(certificate.id)}
                    />
                  </Box>
                ))
              )}
            </Flex>
          </Box>
        </Stack>
      </VStack>
      <ConfirmationModal
        title="Excluir certificado?"
        isOpen={!!deletingCertificate}
        onAccept={handleDeleteCertificate}
        onClose={() => setDeletingCertificate('')}
      />
    </>
  )
}
