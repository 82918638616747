import { useRef, useState } from 'react'
import { GiGraduateCap } from 'react-icons/gi'
import { MdDelete, MdEdit } from 'react-icons/md'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'

import {
  Box,
  Text,
  Icon,
  Stack,
  StackDivider,
  VStack,
  Button,
  UnorderedList,
  ListItem,
  Skeleton,
  HStack,
  IconButton,
  useToast
} from '@chakra-ui/react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { AxiosError } from 'axios'
import * as yup from 'yup'

import { api } from '../../../services/api'
import { formatYupError } from '../../../utils/formatYupError'
import { ConfirmationModal } from '../../ConfirmationModal'
import { Form } from '../../Form/Form'
import { Input } from '../../Form/Input'
import { Modal } from '../../Modal'

type Formation = {
  id: string
  name: string
}

type UseFormationsResult = {
  formations: Formation[]
}

type FormData = {
  name: string
}

export function useFormations(
  options?: UseQueryOptions<UseFormationsResult, AxiosError>
): UseQueryResult<UseFormationsResult, AxiosError> {
  return useQuery<UseFormationsResult, AxiosError>(
    ['me-knowledges'],
    async () => {
      const { data } = await api.get<Formation[]>(`/lawyer/profile/knowledges`)

      return { formations: data }
    },
    {
      staleTime: 1000 * 60 * 1, // One minutes
      ...options
    }
  )
}

export function Formations(): JSX.Element {
  const formRef = useRef<FormHandles>(null)
  const { data, isLoading, error, refetch } = useFormations()
  const [isOpen, setIsOpen] = useState(false)
  const [updatingFormation, setUpdatingFormation] = useState<
    Formation | undefined
  >(undefined)
  const [deletingFormation, setDeletingFormation] = useState('')

  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: 'top-right'
  })

  const handleSubmit: SubmitHandler<FormData> = async data => {
    try {
      if (updatingFormation) {
        await api.put(
          `/lawyer/profile/knowledges/${updatingFormation.id}`,
          data
        )
        setUpdatingFormation(undefined)
        toast({
          status: 'success',
          description: 'Formação atualizada com sucesso'
        })
      } else {
        await api.post(`/lawyer/profile/knowledges`, data)
        setIsOpen(false)
        toast({
          status: 'success',
          description: 'Formação adicionada com sucesso'
        })
      }
      refetch()
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        formRef.current?.setErrors(formatYupError(error))
      } else {
        toast({
          status: 'success',
          description: 'Erro ao tentar adicionar ou atualizar as formações'
        })
      }
    }
  }

  async function handleDeleteFormation(): Promise<void> {
    try {
      await api.delete(`/lawyer/profile/knowledges/${deletingFormation}`)
      setDeletingFormation('')
      refetch()
      toast({
        status: 'success',
        description: 'Formação removida com sucesso'
      })
    } catch (error) {
      toast({
        status: 'error',
        description: 'Erro ao tentar excluir a formação'
      })
    }
  }

  return (
    <>
      <VStack divider={<StackDivider />}>
        <Stack w="full" direction={['column', 'column', 'row']}>
          <Icon as={GiGraduateCap} color="green.300" w="7" h="7" mt="2" />
          <Box w="full">
            <Stack
              w="full"
              justify="space-between"
              direction={['column', 'column', 'row']}
            >
              <Text fontWeight="600" fontSize="xl">
                Formações
              </Text>
              <Box>
                <Button
                  variant="link"
                  color="green.300"
                  mr="2"
                  onClick={() => setIsOpen(true)}
                >
                  Adicionar formação
                </Button>
              </Box>
            </Stack>
            <UnorderedList ml="0" mt="3">
              {error ? (
                <Text color="red.400" fontWeight="medium">
                  Falha ao tentar buscar os suas formações
                </Text>
              ) : isLoading ? (
                <>
                  <ListItem
                    w="full"
                    d="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    gridGap="2"
                  >
                    <Skeleton w="100px" h="32px" />

                    <HStack>
                      <Skeleton w="40px" h="40px" />
                      <Skeleton w="40px" h="40px" />
                    </HStack>
                  </ListItem>
                </>
              ) : data?.formations.length === 0 ? (
                <Text fontWeight="medium">
                  Você não possui formações cadastradas.
                </Text>
              ) : (
                data?.formations.map(formation => (
                  <ListItem
                    key={formation.id}
                    w="full"
                    d="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    gridGap="2"
                  >
                    {formation.name}
                    <Box minW="90px">
                      <IconButton
                        mr="2"
                        variant="unstyled"
                        fontSize="20"
                        color="blue.300"
                        _hover={{ backgroundColor: 'gray.100' }}
                        icon={<Icon as={MdEdit} />}
                        aria-label={`Atualizar formação: ${formation.name}`}
                        onClick={() => setUpdatingFormation(formation)}
                      />
                      <IconButton
                        variant="unstyled"
                        fontSize="20"
                        color="red.400"
                        _hover={{ backgroundColor: 'gray.100' }}
                        icon={<Icon as={MdDelete} />}
                        aria-label={`Excluir formação: ${formation.name}`}
                        onClick={() => setDeletingFormation(formation.id)}
                      />
                    </Box>
                  </ListItem>
                ))
              )}
            </UnorderedList>
          </Box>
        </Stack>
      </VStack>
      <Modal
        title="Formação"
        isOpen={isOpen || !!updatingFormation}
        onClose={() =>
          isOpen ? setIsOpen(false) : setUpdatingFormation(undefined)
        }
      >
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={updatingFormation}
          d="flex"
          flexDirection="column"
          alignItems="flex-end"
        >
          <Input
            name="name"
            label="Formação"
            placeholder="Informe sua formação"
          />
          <Button
            colorScheme="yellow"
            mt="4"
            onClick={() => formRef.current?.submitForm()}
          >
            {isOpen ? 'Cadastrar' : 'Atualizar'}
          </Button>
        </Form>
      </Modal>
      <ConfirmationModal
        title="Excluir formação?"
        isOpen={!!deletingFormation}
        onAccept={handleDeleteFormation}
        onClose={() => setDeletingFormation('')}
      />
    </>
  )
}
