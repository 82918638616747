import { useEffect, useRef } from 'react'

import {
  CheckboxProps as ChakraCheckboxProps,
  Checkbox as ChakraCheckbox,
  FormErrorMessage,
  FormControl,
  Text
} from '@chakra-ui/react'
import { useField } from '@unform/core'

type CheckboxProps = ChakraCheckboxProps & {
  name: string
  label?: string
}

export function Checkbox({
  name,
  children,
  ...rest
}: CheckboxProps): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null)
  const { defaultValue, error, fieldName, registerField, clearError } =
    useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked'
    })
  }, [fieldName, registerField])

  return (
    <FormControl isInvalid={!!error}>
      <ChakraCheckbox
        ref={inputRef}
        defaultChecked={defaultValue}
        size="lg"
        colorScheme="whatsapp"
        d="flex"
        alignItems="stretch"
        onChange={clearError}
        {...rest}
      >
        <Text fontSize="md" mt="-1">
          {children}
        </Text>
      </ChakraCheckbox>
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  )
}
