import { IoMdInformationCircle } from 'react-icons/io'
import { useLocation } from 'react-router-dom'

import { Box, Flex, Icon, Text } from '@chakra-ui/react'

import { useAuth } from '../hooks/useAuth'
import { Tooltip } from './Tooltip'

export type CardProps = {
  id?: number
  name: string
  value: number | string
}

type SmallCardProps = {
  card: CardProps
}

export function SmallCard({ card }: SmallCardProps): JSX.Element {
  const { user } = useAuth()
  const isRating = card.name === 'Minha Nota'
  const information = 'Informação indisponível'
  const isAssessmentsPage = useLocation().pathname === '/assessments'

  function formatDecimals(value: number | string): number | undefined | string {
    const number = value as number
    if (number) {
      if (number > 4.99) {
        return Math.trunc(number)
      } else {
        return parseInt(String(number)).toFixed(1)
      }
    }
  }

  function formatValue(
    value: number | string | undefined | null
  ): number | string | undefined {
    if (isRating) {
      if (isAssessmentsPage) {
        return !value ? 'Novo' : value === 0 ? 'N/A' : formatDecimals(value)
      }
      if (!value || value === 0) {
        return 'N/A'
      }
      return formatDecimals(value)
    }
    return value || 0
  }

  return (
    <Box
      w={['full', 145]}
      h={142}
      borderRadius="6"
      boxShadow="md"
      p="11"
      mb={8}
    >
      <Flex direction="row" justify="space-between" alignItems="center">
        <Text fontSize={12}>{card?.name}</Text>
        {isRating && card.value === 0 && (
          <Tooltip label={information} placement="right">
            <button type="button" aria-label="Abrir informação extra">
              <Icon as={IoMdInformationCircle} />
            </button>
          </Tooltip>
        )}
      </Flex>
      <Flex
        direction="row"
        justify="center"
        alignItems="center"
        h={'80px'}
        w="full"
      >
        <Text
          filter={`blur(${user?.plan === 'free' ? '0.5rem' : '0'})`}
          fontWeight={700}
          fontSize={typeof card.value === 'number' ? 35 : 25}
          color={'green.300'}
        >
          {formatValue(card?.value)}
        </Text>
      </Flex>
    </Box>
  )
}
