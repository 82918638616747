import { RiMenuLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'

import {
  Icon,
  IconButton,
  useBreakpointValue,
  Flex,
  Image
} from '@chakra-ui/react'

import { useSidebarDrawer } from '../hooks/useSideBarDrawer'

export function Header(): JSX.Element {
  const { onOpen } = useSidebarDrawer()
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })

  return (
    <Flex as="header" px="4" w="100%" minH="60px" align="center" bg="gray.700">
      {isWideVersion !== undefined && !isWideVersion && (
        <IconButton
          onClick={onOpen}
          icon={<Icon as={RiMenuLine} />}
          mr="10"
          fontSize="22"
          variant="unstyled"
          color="white"
          aria-label="Open navigation"
        />
      )}
      <Link to="/home">
        <Image src="/logo.svg" alt="uniadvogados" w="150px" />
      </Link>
    </Flex>
  )
}
