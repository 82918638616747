import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import { Center, Flex, Grid, GridItem, Text } from '@chakra-ui/react'

import { PageTemplate } from '../components/PageTemplate'
import { Rating, RatingProps } from '../components/Rating'
import { SkeletonRating } from '../components/SkeletonRating'
import { CardProps, SmallCard } from '../components/SmallCard'
import { SmallCardSkeleton } from '../components/SmallCardSkeleton'
import { api } from '../services/api'

export function Assessments(): JSX.Element {
  const [ratingValues, setRatingValues] = useState<RatingProps[]>()
  const [refresh, setRefresh] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [page, setPage] = useState<number>(1)
  const [hasMore, setMore] = useState<boolean>(false)
  const [cardValue, setCardValue] = useState<CardProps>({
    id: 1,
    name: 'Minha Nota',
    value: 0
  })

  useEffect(() => {
    async function fetchCardData(): Promise<void> {
      try {
        setLoading(true)
        const { data } = await api.get(`/lawyer/ratings/total`)

        setCardValue({
          ...cardValue,
          value: data.rating
        })
      } catch {
        setError(true)
      } finally {
        setLoading(false)
      }
    }
    fetchCardData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function fetchData(): Promise<void> {
      try {
        const { data } = await api.get(`lawyer/ratings?page=1&limit=10`)
        if (data.length > 9) {
          setMore(true)
        }
        setRatingValues(data)
      } catch (error) {
        setError(true)
      }
    }
    fetchData()
  }, [refresh])

  async function loadMoreRating(): Promise<void> {
    const newValuePage = page + 1
    try {
      const { data } = await api.get(
        `lawyer/ratings?page=${newValuePage}&limit=10`
      )
      if (ratingValues && data.length > 0) {
        setPage(page + 1)
        setRatingValues([...ratingValues, ...data])
      } else {
        setMore(false)
      }
    } catch (error) {
      setError(true)
    }
  }

  return (
    <PageTemplate>
      <Flex w={'full'}>
        {error ? (
          <Flex align={'center'} justify={'center'} w={'full'}>
            <Text alignSelf="center" marginTop={20}>
              Falha ao obter os dados das avaliações.
            </Text>
          </Flex>
        ) : (
          <Grid
            gap="8"
            templateColumns={['1fr', '1fr', '0.4fr 1fr']}
            mt={4}
            w={'full'}
          >
            <GridItem
              d={'flex'}
              alignItems={['center', '']}
              flexDir={'column'}
              colSpan={1}
            >
              {loading ? <SmallCardSkeleton /> : <SmallCard card={cardValue} />}
            </GridItem>
            <GridItem alignItems={'center'} colSpan={1} pr={['', '', 20]}>
              {loading ? (
                [...Array(4)].map((_value, index) => (
                  <SkeletonRating key={index} />
                ))
              ) : ratingValues && ratingValues.length > 0 ? (
                <InfiniteScroll
                  dataLength={ratingValues?.length || 0}
                  hasMore={hasMore}
                  next={loadMoreRating}
                  loader={<SkeletonRating />}
                  style={{ overflow: 'inherit' }}
                  endMessage={
                    !loading && (
                      <Center>
                        <Text>Por enquanto, isso é tudo o que temos :)</Text>
                      </Center>
                    )
                  }
                >
                  {ratingValues.map(rating => (
                    <Rating
                      key={rating.id}
                      rating={rating}
                      reFetch={() => setRefresh(!refresh)}
                    />
                  ))}
                </InfiniteScroll>
              ) : (
                <Center p={10}>
                  <Text>Você ainda não possui nenhuma avaliação</Text>
                </Center>
              )}
            </GridItem>
          </Grid>
        )}
      </Flex>
    </PageTemplate>
  )
}
