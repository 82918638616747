import { Link as ReactLink } from 'react-router-dom'

import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Link as ChakraLink,
  useBreakpointValue,
  Box,
  Image
} from '@chakra-ui/react'

import { useQueryParams } from '../../hooks/useQueryParams'
import { useSidebarDrawer } from '../../hooks/useSideBarDrawer'
import { SidebarNav } from './SidebarNav'

export function SideBar(): JSX.Element {
  const { isOpen, onClose } = useSidebarDrawer()
  const isDrawerSidebar = useBreakpointValue({
    base: true,
    lg: false
  })
  const query = useQueryParams()
  const firstAccess = !query.get('firstAccess')

  if (isDrawerSidebar) {
    return (
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent bg="gray.700" color="white">
            <DrawerCloseButton mt="6" />
            <DrawerHeader>Navegação</DrawerHeader>
            <DrawerBody p="0">{firstAccess && <SidebarNav />}</DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    )
  }
  return (
    <Box
      as="aside"
      w="56"
      h="full"
      py="4"
      bg="gray.700"
      color="white"
      position="fixed"
      top="0"
      left="0"
      boxShadow="md"
      borderTopRightRadius="48px"
      borderBottomRightRadius="48px"
    >
      <ChakraLink as={ReactLink} to="/home" d="inline-block" marginLeft="16px">
        <Image src="/logo.svg" alt="uniadvogados" w="150px" />
      </ChakraLink>
      {firstAccess && <SidebarNav />}
    </Box>
  )
}
