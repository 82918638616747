export function transformEmptyStringToNull(
  // eslint-disable-next-line @typescript-eslint/ban-types
  object: Record<string, unknown> | object
): Record<string, unknown> {
  if (object === undefined) {
    object = {}
  }
  return Object.entries(object).reduce(function (acc, [key, value]) {
    if (typeof value === 'string' && value === '') acc[key] = null
    else if (typeof value === 'object') {
      if (value instanceof Array) acc[key] = value
      else if (value === null) acc[key] = value
      else acc[key] = transformEmptyStringToNull(value)
    } else acc[key] = value

    return acc
  }, {} as Record<string, unknown>)
}
