import { extendTheme, ThemeOverride } from '@chakra-ui/react'

export const theme = extendTheme({
  shadows: {
    md: '0px 0px 15px rgba(0, 0, 0, 0.15)',
    outline: '0 0 0 3px #EFE838'
  },
  components: {
    Skeleton: {
      defaultProps: {
        startColor: 'gray.100',
        endColor: 'gray.200'
      },
      baseStyle: {
        borderRadius: '4px'
      }
    },
    Button: {
      variants: {
        link: ({ colorScheme }) =>
          colorScheme === 'green' && {
            color: 'green.300'
          },
        solid: ({ colorScheme }) =>
          colorScheme === 'yellow' && {
            bg: 'yellow.300',
            color: 'gray.700',
            _hover: {
              bg: 'yellow.400',
              _disabled: {
                bg: 'yellow.500'
              }
            },
            _active: {
              bg: 'yellow.400'
            }
          }
      }
    },

    Link: {
      baseStyle: {
        _focus: {
          outline: '1.5px solid #EFE838'
        }
      }
    }
  },
  colors: {
    red: {
      '300': '#EB5757'
    },
    green: {
      '300': '#3BD8B2'
    },
    yellow: {
      '400': '#F6E05E',
      '300': '#EFE838'
    },
    blue: {
      '300': '#58B6F1',
      '900': '#0B4265'
    },
    gray: {
      '900': '#181B23',
      '800': '#1F2029',
      '700': '#383838',
      '600': '#636363',
      '500': '#616480',
      '400': '#797D9A',
      '300': '#9699B0',
      '200': '#B3B5C6',
      '100': '#E6E4E4',
      '50': '#F8F8F8'
    }
  },
  fonts: {
    heading: 'Red Hat Display',
    body: 'Assistant'
  },
  styles: {
    global: {
      body: {
        color: 'gray.700'
      },
      'h1, h2, h3, h4, h5, h6': {
        color: 'gray.600'
      }
    }
  }
} as ThemeOverride)
