/* eslint-disable prettier/prettier */
import { ComponentType } from 'react'
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
  RouteComponentProps
} from 'react-router-dom'

import { useAuth } from '../hooks/useAuth'

export type RouterProps = ReactDOMRouteProps & {
  isPrivate?: boolean
  path: string
  component: ComponentType<RouteComponentProps> | ComponentType
}

export function Route({
  component: Component,
  isPrivate = false,
  path,
  ...rest
}: RouterProps): JSX.Element {
  const { isLogged, isAuthenticated } = useAuth()

  if (!isAuthenticated && !!isLogged && path !== '/pin') {
    return <Redirect to="/pin" />
  }
  if (isPrivate && !isLogged && !isAuthenticated && path !== '/signin') {
    return <Redirect to="/signin" />
  }

  return <ReactDOMRoute render={props => <Component {...props} />} {...rest} />
}
