import { useRef, useState } from 'react'
import { Link as ReactLink } from 'react-router-dom'

import {
  Box,
  Button,
  Center,
  Divider,
  Grid,
  Heading,
  Img,
  Link,
  useBreakpointValue
} from '@chakra-ui/react'
import { FormHandles, SubmitHandler } from '@unform/core'
import * as yup from 'yup'

import { Form } from '../components/Form/Form'
// import { HCaptcha } from '../components/Form/HCaptcha'
import { Input } from '../components/Form/Input'
import { useAuth } from '../hooks/useAuth'
import { passwordSchema } from '../utils/defaultSchemas'
import { formatYupError } from '../utils/formatYupError'

type FormData = {
  email: string
  password: string
}

const loginFormSchema = yup.object({
  email: yup
    .string()
    .email('E-mail Inválido.')
    .required('E-mail é obrigatório.'),
  password: passwordSchema('Senha é obrigatória.')
  /* captcha: yup
    .boolean()
    .isTrue('Deve realizar o captcha.')
    .required('Captcha é obrigatório.') */
})

export function SignIn(): JSX.Element {
  const formRef = useRef<FormHandles>(null)
  const isWideVersion = useBreakpointValue({ base: false, md: true })
  const { signIn } = useAuth()
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit: SubmitHandler<FormData> = async data => {
    setIsLoading(true)
    try {
      await loginFormSchema.validate(data, { abortEarly: false })
      await signIn(data)
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        formRef.current?.setErrors(formatYupError(error))
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Grid
      w="full"
      h="100vh"
      gridTemplateColumns={isWideVersion ? '1fr 1fr' : '1fr'}
      gridTemplateRows={isWideVersion ? '1fr' : '185px 1fr'}
    >
      <Box pos="relative">
        <Img
          src="/lawy.webp"
          alt="advogados"
          w="full"
          h={isWideVersion ? '100%' : '185px'}
          objectFit="cover"
          objectPosition={['', 'left']}
        />

        <Img
          src="/logo.svg"
          alt="uniadvogados"
          pos="absolute"
          w="100%"
          maxW={['200px', '300px', '400px']}
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        />
      </Box>
      <Center p="4" d="flex" flexDirection="column">
        <Box
          w="full"
          maxW="400"
          pt="5"
          pb="7"
          px="9"
          borderRadius="4"
          boxShadow="md"
        >
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            d="flex"
            flexDirection="column"
          >
            <Heading fontSize="xl">Acessar conta</Heading>
            <Divider my="4" />
            <Input
              name="email"
              label="E-mail"
              placeholder="Informe seu e-mail"
              mb="4"
            />
            <Input
              name="password"
              label="Senha"
              placeholder="Informe sua senha"
              type="password"
              mb="3"
            />

            <Link
              as={ReactLink}
              to="forgot"
              ml="auto"
              mb={4}
              textDecoration="underline"
            >
              Esqueci minha senha.
            </Link>
            {/* <HCaptcha name="captcha" /> */}
            <Button
              type="submit"
              colorScheme="yellow"
              w="full"
              h="48px"
              mt="5"
              fontSize="lg"
              isLoading={isLoading}
            >
              Entrar
            </Button>
          </Form>
        </Box>

        <Link as={ReactLink} to="signup" mt="4">
          Não possui uma conta? inscreva-se
        </Link>
      </Center>
    </Grid>
  )
}
