import axios from 'axios'

import { signOut } from '../contexts/AuthContext'

export const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
})

api.interceptors.request.use(
  function (config) {
    return config
  },
  function (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 401) {
        signOut()
      }
    }
    return Promise.reject(error)
  }
)
