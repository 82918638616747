import { useEffect, useRef } from 'react'
import { IoAlertCircleOutline } from 'react-icons/io5'

import {
  TextareaProps as ChakraTextareaProps,
  Textarea as ChakraTextarea,
  theme,
  IconButton,
  Icon,
  FormControl,
  FormLabel,
  Box
} from '@chakra-ui/react'
import { useField } from '@unform/core'

import { Tooltip } from '../Tooltip'

type TextareaProps = ChakraTextareaProps & {
  name: string
  label?: string
}

export function Textarea({ name, label, ...rest }: TextareaProps): JSX.Element {
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const { defaultValue, error, fieldName, registerField, clearError } =
    useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textareaRef.current,
      path: 'value'
    })
  }, [fieldName, registerField])

  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <FormLabel mb="4" d="flex" alignItems="center" gridGap="2">
          {label}
        </FormLabel>
      )}
      <Box pos="relative">
        <ChakraTextarea
          ref={textareaRef}
          defaultValue={defaultValue}
          colorScheme="yellow"
          pr={error ? '12' : '0'}
          onFocus={clearError}
          _focus={{
            boxShadow: `0 0 0 1px ${theme.colors.yellow['300']}`,
            borderColor: 'yellow.300'
          }}
          {...rest}
        />
        {!!error && (
          <Tooltip label={error} placement="top-end" aria-label="Error tooltip">
            <IconButton
              d="flex"
              position="absolute"
              top="0"
              right="2"
              minW="8"
              h="8"
              m="2"
              icon={<Icon as={IoAlertCircleOutline} />}
              fontSize="22"
              variant="unstyled"
              aria-label="Abrir mensagem de erro"
              color="red.500"
            />
          </Tooltip>
        )}
      </Box>
    </FormControl>
  )
}
