import { UFs, UFS } from './validations'

function formatCpf(value: string): string {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

function formatCnpj(value: string): string {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

export function formatCpfCnpj(value: string): string {
  const cleanValue = String(value).replace(/\D/g, '')

  if (cleanValue.length <= 11) {
    return formatCpf(value)
  }
  return formatCnpj(value)
}

export function formatOAB(value: string): string {
  const cleanedValue = value
    .replace('OAB/', '')
    .replaceAll('-', '')
    .replaceAll(' ', '')
  const UF = cleanedValue.substr(0, 2).replace(/\d/g, '').toUpperCase() as UFS
  const number = cleanedValue.substr(2, 6).replace(/\D/g, '')
  if (UF.length < 2) {
    if (UF.length === 0) return ''
    return `OAB/${UF}`
  } else {
    if (number.length === 0) {
      if (UF.length === 2) {
        const isUf = !!UFs[UF]
        if (isUf) return `OAB/${UF}`
        else return 'OAB/'
      }
      return `OAB/${UF}`
    }
    return `OAB/${UF}-${number}`
  }
}

export function formatPhone(phone: string): string {
  if (phone) {
    const value = phone.replace(/\D/g, '')
    const { length } = value

    if (length <= 8) {
      return value
        .replace(/(\d{4})(\d{1,4})/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1')
    }
    if (length === 9) {
      return value
        .replace(/(\d{5})(\d{1,5})/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1')
    }
    if (length === 10) {
      return value
        .replace(/(\d{0})(\d{1,2})/, '$1($2')
        .replace(/(\d{2})(\d)/, '$1) $2')
        .replace(/(\d{4})(\d{1,4})/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1')
    }
    if (length === 11) {
      return value
        .replace(/(\d{0})(\d{1,2})/, '$1($2')
        .replace(/(\d{2})(\d)/, '$1) $2')
        .replace(/(\d{5})(\d{1,4})/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1')
    }
    return value
      .replace(/(\d{0})(\d{1,2})/, '$1($2')
      .replace(/(\d{2})(\d)/, '$1) $2')
      .replace(/(\d{5})(\d{1,4})/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1')
  }
  return ''
}

export function formatCep(value: string): string {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1')
}

export function formatCreditCard(value: string): string {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{4})/g, '$1 ')
    .replace(/\.$/, '')
    .substring(0, 19)
}

export function formatExpiryDate(value: string): string {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{0})(\d{1,2})/, value.length >= 3 ? '$1$2/' : '$1$2')
    .replace(/(\/\d{2})\d+?$/, '$1')
}

export function formatNumber(value: string): string {
  return value.replace(/\D/g, '')
}

export function hideCardNumber(value: string): string {
  const regExp = /([0-9]{4})[\s]([0-9]{4})[\s]([0-9]{4})[\s]([0-9]{4})/g
  const format = '●●●● $4'
  return value.replace(regExp, format)
}

export const masks = {
  cpf: formatCpf,
  cnpj: formatCnpj,
  cpfCnpj: formatCpfCnpj,
  phone: formatPhone,
  oab: formatOAB,
  cep: formatCep,
  creditCard: formatCreditCard,
  expiryDate: formatExpiryDate,
  number: formatNumber,
  hideCardNumber
}
