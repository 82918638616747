import { IoCheckmarkSharp } from 'react-icons/io5'

import { Box, Icon } from '@chakra-ui/react'

type EllipseProps = {
  isChecked?: boolean
}

export function Ellipse({ isChecked }: EllipseProps): JSX.Element {
  return (
    <Box
      d={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      w={'27px'}
      h={'22px'}
      marginRight={5}
      bgColor={isChecked ? 'green.300' : 'gray.50'}
      border={!isChecked ? '1px solid #E6E4E4' : ''}
      borderRadius={'50%'}
    >
      {isChecked && (
        <Icon as={IoCheckmarkSharp} color={'white'} h={'12px'} w={'15px'} />
      )}
    </Box>
  )
}
