import { ReactNode } from 'react'

import {
  Button,
  Divider,
  Flex,
  Heading,
  Spinner,
  StyleProps
} from '@chakra-ui/react'

type FormContainerProps = StyleProps & {
  children: ReactNode
  buttonText?: string
  headerText: string
  offButton?: boolean
  loadingData?: boolean
  loading?: boolean
  isMinContent?: boolean
}

export function FormContainer({
  buttonText,
  headerText,
  offButton,
  loading,
  loadingData,
  isMinContent,
  children,
  ...props
}: FormContainerProps): JSX.Element {
  return (
    <Flex
      w="full"
      h={isMinContent ? 'min-content' : 'full'}
      py="5"
      px="9"
      borderRadius="6"
      boxShadow="md"
      d="flex"
      flexDirection="column"
      {...props}
    >
      <Heading fontSize="xl">
        {headerText}
        {loadingData && (
          <Spinner
            ml="4"
            size="md"
            thickness="2px"
            speed="0.65s"
            emptyColor="gray.700"
          />
        )}
      </Heading>
      <Divider my="4" color={'#E6E4E4'} h={'0.5px'} />
      {children}
      {!offButton && (
        <Button
          type="submit"
          alignSelf={'flex-end'}
          colorScheme="yellow"
          w="120px"
          h="35px"
          mt="4"
          fontSize={'small'}
          isLoading={loading}
        >
          {buttonText}
        </Button>
      )}
    </Flex>
  )
}
