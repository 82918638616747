import { IoClose } from 'react-icons/io5'

import { Divider, HStack, Icon, IconButton, Text } from '@chakra-ui/react'

type ServiceItemProps = {
  label: string
  onDelete: () => void
}

export function ServiceItem({
  label,
  onDelete
}: ServiceItemProps): JSX.Element {
  return (
    <HStack
      spacing="2"
      align="center"
      py="1"
      px="2"
      borderRadius="4"
      backgroundColor="blue.300"
    >
      <Text
        color="blue.900"
        fontWeight="bold"
        borderRightColor="blue.900"
        borderRightWidth="2"
        borderRightStyle="solid"
      >
        {label}
      </Text>
      <Divider
        orientation="vertical"
        borderColor="blue.900"
        h="4"
        borderWidth="1px"
        marginInline="4px"
      />
      <IconButton
        h="5"
        minW="5"
        backgroundColor="blue.300"
        _hover={{ backgroundColor: 'blue.400' }}
        aria-label="Excluir  São paulo"
        onClick={onDelete}
        icon={<Icon as={IoClose} />}
      />
    </HStack>
  )
}
