import { useEffect, useRef } from 'react'
import { IoAlertCircleOutline } from 'react-icons/io5'

import {
  SelectProps as ChakraSelectProps,
  Select as ChakraSelect,
  theme,
  IconButton,
  Icon,
  FormControl,
  FormLabel,
  Skeleton
} from '@chakra-ui/react'
import { useField } from '@unform/core'

import { Tooltip } from '../Tooltip'

export type Option = {
  label: string
  value: string | number
}

type SelectProps = ChakraSelectProps & {
  name: string
  label?: string
  options: Option[]
  isLoading?: boolean
}

export function Select({
  name,
  label,
  options,
  isLoading = false,
  ...rest
}: SelectProps): JSX.Element {
  const selectRef = useRef<HTMLSelectElement>(null)
  const { defaultValue, error, fieldName, registerField, clearError } =
    useField(name)

  useEffect(() => {
    if (!isLoading) {
      registerField({
        name: fieldName,
        ref: selectRef.current,
        path: 'value'
      })
    }
  }, [fieldName, registerField, isLoading])

  return (
    <FormControl isInvalid={!!error} position="relative">
      {label && <FormLabel mb="4">{label}</FormLabel>}
      {isLoading ? (
        <Skeleton w="100%" h="40px" />
      ) : (
        <ChakraSelect
          ref={selectRef}
          defaultValue={defaultValue}
          colorScheme="yellow"
          onFocus={clearError}
          _focus={{
            boxShadow: `0 0 0 1px ${theme.colors.yellow['300']}`,
            borderColor: 'yellow.300'
          }}
          {...rest}
        >
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </ChakraSelect>
      )}
      {!!error && (
        <Tooltip
          hasArrow
          arrowSize={15}
          label={error}
          px="2"
          py="1"
          placement="top-end"
          aria-label="Error tooltip"
        >
          <IconButton
            d="flex"
            icon={<Icon as={IoAlertCircleOutline} />}
            fontSize="22"
            variant="unstyled"
            aria-label="Open error message"
            color="red.500"
            position="absolute"
            top="50%"
            translateY="-50%"
            right="28px"
          />
        </Tooltip>
      )}
    </FormControl>
  )
}
