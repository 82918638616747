import * as yup from 'yup'

import { api } from '../services/api'

const uploadImageFormSchema = yup.object().shape({
  image: yup
    .mixed()
    .test(
      'Image Upload',
      'O arquivo deve ser menor que 10MB',
      (value: File) => value && value.size < 10000000
    )
    .test(
      'Image Upload',
      'Somente são aceitos arquivos PNG JPG e JPEG',
      (value: File) => value && /.*\/(jpeg|jpg|png)$/gim.test(value.type)
    )
    .test('Image Upload', 'Arquivo obrigatório', (value: File) => !!value)
})

export type ImageUploadResponse = {
  filePath: string
  fileName: string
}

export async function uploadImage(
  image: File | undefined
): Promise<ImageUploadResponse> {
  const res = await uploadImageFormSchema.validate(
    { image },
    {
      abortEarly: false
    }
  )

  const formData = new FormData()

  formData.append('file', res.image)

  const { data } = await api.patch<ImageUploadResponse>('file', formData, {
    headers: { 'content-type': 'multipart/form-data' }
  })
  return data
}
