export function formatPrice(
  number: number | string | null | undefined
): string {
  if (number === null || number === undefined) {
    return 'R$ 0,00'
  }
  const formattedNumber = Number(number)

  if (Number.isNaN(formattedNumber)) {
    return 'R$ 0,00'
  }

  return `R$ ${formattedNumber.toFixed(2).replace('.', ',')}`
}
