import { useRef, useState } from 'react'
import { IoMdTrash } from 'react-icons/io'
import { useLocation } from 'react-router-dom'

import {
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useToast
} from '@chakra-ui/react'
import { FormHandles, SubmitHandler } from '@unform/core'
import axios from 'axios'
import * as yup from 'yup'

import { api } from '../services/api'
import { formatDate } from '../utils/formatDate'
import { formatYupError } from '../utils/formatYupError'
import { CleanModal } from './CleanModal'
import { Form } from './Form/Form'
import { Select } from './Form/Select'
import { Textarea } from './Form/Textarea'
import { FormContainer } from './FormContainer'
import { StarRating } from './StarRating'

export type RatingProps = {
  id: number
  comment: string
  response: string | null
  fullName: string
  createdAt: string
  rating: number
  photo: string
}

type RatingCompProps = {
  rating: RatingProps
  reFetch?: () => void
}

const textAreaSchema = yup.object({
  response: yup
    .string()
    .min(2, 'Precisa de mais caracteres.')
    .max(350, 'Maximo de caracteres alcançado')
    .required('Comentario é obrigatório.')
})

const deleteAreaSchema = yup.object({
  justify: yup
    .string()
    .min(2, 'Precisa de mais caracteres.')
    .max(350, 'Maximo de caracteres alcançado')
    .required('justificativa é obrigatória.')
})

const justifyOptions = [
  {
    label: 'O cliente foi ofensivo',
    value: 'O cliente foi ofensivo'
  },
  {
    label: 'Não é meu cliente',
    value: 'Não é meu cliente'
  },
  {
    label: 'Outros',
    value: 'Outros'
  }
]

export function Rating({ rating, reFetch }: RatingCompProps): JSX.Element {
  const [deleteRating, setDeleteRating] = useState<boolean>(false)
  const [toggle, setToggle] = useState<boolean>(false)
  const isAssessmentsPage = useLocation().pathname === '/assessments'
  const hasAnswer = rating.response
  const formRef = useRef<FormHandles>(null)

  const toast = useToast({
    position: 'top-right',
    duration: 5000,
    isClosable: true
  })

  const handleSubmitRating: SubmitHandler<FormData> = async data => {
    try {
      await textAreaSchema.validate(data, { abortEarly: false })
      await api.put(`/lawyer/ratings/${rating.id}`, {
        ...data
      })
      toast({
        status: 'success',
        title: 'Sucesso!',
        description: 'Sua resposta foi feita com sucesso!.'
      })
      reFetch && reFetch()
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        formRef.current?.setErrors(formatYupError(error))
      } else if (axios.isAxiosError(error)) {
        toast({
          status: 'error',
          title: 'Erro!',
          description: 'Ocorreu um erro ao tentar responder!'
        })
      }
    }
  }

  const handleSubmitDeleteRating: SubmitHandler<FormData> = async data => {
    try {
      await deleteAreaSchema.validate(data, { abortEarly: false })
      await api.patch(`/lawyer/ratings/${rating.id}`, {
        ...data
      })
      toast({
        status: 'success',
        title: 'Sucesso!',
        description: 'Solicitação feita com sucesso!.'
      })
      reFetch && reFetch()
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        formRef.current?.setErrors(formatYupError(error))
      } else if (axios.isAxiosError(error)) {
        toast({
          status: 'error',
          title: 'Erro!',
          description: 'Ocorreu um erro ao tentar solicitar!'
        })
      }
    }
  }

  return (
    <Flex
      w={'full'}
      h={'min-content'}
      p={5}
      borderRadius="6"
      direction={'row'}
      boxShadow="md"
      mb={8}
    >
      <Avatar
        color={'white'}
        fontSize={12}
        bgColor={'green.300'}
        size="sm"
        name={rating.fullName}
        marginRight={3}
      />
      <Flex direction={'column'} w={'full'}>
        <Box>
          <Text fontSize={14}>{rating.fullName}</Text>
          <Flex alignItems={'center'}>
            <StarRating userRating={rating.rating} />
            <Text fontSize={10}>{formatDate(rating.createdAt)}</Text>
          </Flex>
        </Box>
        <Text fontSize={14} p={2} mb={3}>
          {rating.comment}
        </Text>
        {isAssessmentsPage &&
          (!hasAnswer ? (
            <Flex w={'full'}>
              {!toggle && (
                <Flex w={'full'} justifyContent={'end'}>
                  <Button
                    fontWeight={500}
                    variant={'link'}
                    color={'gray.600'}
                    onClick={() => setToggle(!toggle)}
                    fontSize={12}
                  >
                    Responder
                  </Button>
                </Flex>
              )}
              {toggle && (
                <Form ref={formRef} onSubmit={handleSubmitRating} w={'full'}>
                  <Flex w={'full'} dir={'row'} wrap={'nowrap'}>
                    <Textarea
                      name={'response'}
                      placeholder={'Informe sua resposta'}
                    />
                  </Flex>
                  <Flex justify={'space-between'} w={'full'} mt={4}>
                    <Button
                      fontWeight={500}
                      variant={'link'}
                      onClick={() => setToggle(!toggle)}
                      color={'red.300'}
                      fontSize={12}
                    >
                      Cancelar
                    </Button>
                    <Button
                      fontWeight={500}
                      variant={'link'}
                      color={'gray.600'}
                      type={'submit'}
                      fontSize={12}
                    >
                      Concluir
                    </Button>
                  </Flex>
                </Form>
              )}
            </Flex>
          ) : (
            <Flex flexDir={'row'} ml={3}>
              <Box
                bgColor={'gray.50'}
                borderRadius={'20px 20px 0px 20px'}
                p={4}
                w={'full'}
                mr={2}
              >
                <Text>{rating.response}</Text>
              </Box>
              <Avatar
                alignSelf={'end'}
                color={'white'}
                fontSize={12}
                bgColor={'green.300'}
                size="sm"
                src={rating.photo}
              />
            </Flex>
          ))}
      </Flex>
      {isAssessmentsPage && (
        <Icon
          color="red.300"
          w={5}
          h={5}
          as={IoMdTrash}
          cursor="pointer"
          onClick={() => setDeleteRating(true)}
        />
      )}
      <CleanModal isOpen={deleteRating} onClose={() => setDeleteRating(false)}>
        <Form ref={formRef} onSubmit={handleSubmitDeleteRating}>
          <FormContainer
            buttonText={'Solicitar Exclusão'}
            headerText={'Excluir Avaliação'}
          >
            <Select
              label={'Motivo'}
              name={'reason'}
              placeholder={'Selecione o motivo'}
              options={justifyOptions}
              mb={8}
            />
            <Textarea
              name={'justify'}
              label={'Justificativa'}
              placeholder={'Justifique sua solicitação de exclusão'}
              minH={102}
            />
          </FormContainer>
        </Form>
      </CleanModal>
    </Flex>
  )
}
