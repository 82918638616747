export type UFS =
  | 'AC'
  | 'AL'
  | 'AP'
  | 'AM'
  | 'BA'
  | 'CE'
  | 'DF'
  | 'ES'
  | 'GO'
  | 'MA'
  | 'MT'
  | 'MS'
  | 'MG'
  | 'PA'
  | 'PB'
  | 'PR'
  | 'PE'
  | 'PI'
  | 'RJ'
  | 'RN'
  | 'RS'
  | 'RO'
  | 'RR'
  | 'SC'
  | 'SP'
  | 'SE'
  | 'TO'

export const UFs = {
  AC: 'Acre',
  AL: 'Alagoas',
  AP: 'Amapá',
  AM: 'Amazonas',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espírito Santo',
  GO: 'Goiás',
  MA: 'Maranhão',
  MT: 'Mato Grosso',
  MS: 'Mato Grosso do Sul',
  MG: 'Minas Gerais',
  PA: 'Pará',
  PB: 'Paraíba',
  PR: 'Paraná',
  PE: 'Pernambuco',
  PI: 'Piauí',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RS: 'Rio Grande do Sul',
  RO: 'Rondônia',
  RR: 'Roraima',
  SC: 'Santa Catarina',
  SP: 'São Paulo',
  SE: 'Sergipe',
  TO: 'Tocantins'
}

export function validateOab(value: string | undefined): boolean {
  if (!value) return false
  const cleanedValue = value
    .replace('OAB/', '')
    .replaceAll('-', '')
    .replaceAll(' ', '')
  const UF = cleanedValue.substr(0, 2).replace(/\d/g, '').toUpperCase() as UFS
  const number = cleanedValue.substr(2, 6).replace(/\D/g, '')
  return !!UFs[UF] && number.length >= 3 && number.length <= 6
}

export function validateCellphone(cellphone: string | undefined): boolean {
  if (!cellphone) return false
  const cleanedCellphone = cellphone.replace(/\D/g, '')
  const length = cleanedCellphone.length

  return length === 8 ? true : length === 9 ? true : length === 11
}

export function validateCpfCnpj(cpfCnpj: string | undefined): boolean {
  if (!cpfCnpj) return false
  const cleanedCpfCnpj = cpfCnpj.replace(/\D/g, '')
  const length = cleanedCpfCnpj.length

  return length === 11 || length === 14
}

export function validateDate(date: string | undefined): boolean {
  return !!date
}
