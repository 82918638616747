import { ContentWrapper } from '../../ContentWrapper'
import { Certificates } from './Certificates'
import { Courses } from './Courses'
import { Formations } from './Formations'

export function AcademicFormation(): JSX.Element {
  return (
    <ContentWrapper
      w="full"
      title="Formação acadêmica"
      infoLabel="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
    >
      <Formations />
      <Courses />
      <Certificates />
    </ContentWrapper>
  )
}
