import { ReactNode } from 'react'
import { IoInformationCircle as InformationIcon } from 'react-icons/io5'

import {
  Box,
  Flex,
  Divider,
  Heading,
  BoxProps,
  Icon,
  Spinner
} from '@chakra-ui/react'

import { Tooltip } from './Tooltip'

type ContentWrapperProps = BoxProps & {
  title: string
  infoLabel?: string
  isLoading?: boolean
  children?: ReactNode
  headerChildren?: ReactNode
}

export function ContentWrapper({
  title,
  infoLabel,
  children,
  isLoading = false,
  headerChildren,
  ...rest
}: ContentWrapperProps): JSX.Element {
  return (
    <Box boxShadow="md" borderRadius="4" pt="1" {...rest}>
      <Box>
        <Flex
          as="header"
          justify="space-between"
          align="center"
          px="4"
          pt="2"
          flexDirection={['column', 'column', 'row']}
        >
          <Flex align="center" gridGap="3">
            <Heading as="h2" fontSize="2xl">
              {title}
            </Heading>
            {infoLabel && (
              <Tooltip label={infoLabel} aria-label="Informação extra">
                <button type="button" aria-label="Botão abrir informação extra">
                  <Icon as={InformationIcon} fontSize="18" color="black" />
                </button>
              </Tooltip>
            )}
            {isLoading && (
              <Spinner
                w="18px"
                h="18px"
                speed="0.65s"
                thickness="2px"
                emptyColor="gray.200"
              />
            )}
          </Flex>
          {headerChildren}
        </Flex>
        <Divider mt="2" mb="3" />
      </Box>
      <Box px="4" pb="6">
        {children}
      </Box>
    </Box>
  )
}
