import {
  Divider,
  Modal as ChakraModal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps as ChakraModalProps
} from '@chakra-ui/react'

type ModalProps = ChakraModalProps & {
  title: string
}

export function Modal({ title, children, ...rest }: ModalProps): JSX.Element {
  return (
    <ChakraModal {...rest}>
      <ModalOverlay />
      <ModalContent my="auto" mx="4">
        <ModalHeader fontSize="2xl">
          {title}
          <Divider mt="2" />
        </ModalHeader>
        <ModalBody pt="4" px="6" pb="6">
          {children}
        </ModalBody>
      </ModalContent>
    </ChakraModal>
  )
}
