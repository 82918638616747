import { useMemo, useRef, useState } from 'react'
import { MdDelete, MdDeviceHub, MdEdit } from 'react-icons/md'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'

import {
  Box,
  Stack,
  Icon,
  Text,
  UnorderedList,
  ListItem,
  IconButton,
  VStack,
  StackDivider,
  Button,
  HStack,
  Skeleton,
  useToast
} from '@chakra-ui/react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { AxiosError } from 'axios'
import * as yup from 'yup'

import { useFields } from '../../hooks/useFields'
import { getServices } from '../../hooks/useServices'
import { api } from '../../services/api'
import { formatYupError } from '../../utils/formatYupError'
import { ConfirmationModal } from '../ConfirmationModal'
import { ContentWrapper } from '../ContentWrapper'
import { Form } from '../Form/Form'
import { Option, Select } from '../Form/Select'
import { Modal } from '../Modal'

type Field = {
  id: string
  fieldId: string
  name: string
  slug: string
  services: Array<{
    id: string
    serviceId: string
    name: string
  }>
}

type UseMyFieldsResult = {
  fields: Field[]
}

type FormServiceData = {
  serviceId: string
}

type FormFieldData = {
  fieldId: string
}

type UpdatingServiceData = {
  id: string
  fieldId: string
  serviceId?: string
}
type CreatingServiceData = {
  id: string
  fieldId: string
}

type OpenServiceData = {
  id: string
  fieldId: string
  service?: {
    id: string
    serviceId: string
  }
}

export function useMyFields(
  options?: UseQueryOptions<UseMyFieldsResult, AxiosError>
): UseQueryResult<UseMyFieldsResult, AxiosError> {
  return useQuery<UseMyFieldsResult, AxiosError>(
    ['me-fields'],
    async () => {
      const { data } = await api.get<Field[]>(`/lawyer/profile/fields`)

      return { fields: data }
    },
    {
      staleTime: 1000 * 60 * 1, // One minutes
      ...options
    }
  )
}

const fieldFormSchema = yup.object({
  fieldId: yup.string().required('Área de atuação é obrigatória.')
})
const serviceFormSchema = yup.object({
  serviceId: yup.string().required('Serviço é obrigatório.')
})

export function Fields(): JSX.Element {
  const formRef = useRef<FormHandles>(null)
  const [fieldIsOpen, setFieldIsOpen] = useState(false)
  const [services, setServices] = useState<Option[]>([])
  const [deletingField, setDeletingField] = useState('')
  const [deletingService, setDeletingService] = useState('')
  const [updatingService, setUpdatingService] = useState<
    UpdatingServiceData | undefined
  >(undefined)
  const [creatingService, setCreatingService] = useState<
    CreatingServiceData | undefined
  >(undefined)
  const { data, isLoading, error, isFetching, refetch } = useMyFields()
  const { data: fields } = useFields()
  const formattedFields = useMemo(() => {
    if (!fields) return []
    return fields.fields.map(field => ({ value: field.id, label: field.name }))
  }, [fields])

  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: 'top-right'
  })

  async function openServiceModal(data: OpenServiceData): Promise<void> {
    try {
      const services = (await getServices(data.fieldId)).services.map(
        service => ({ value: service.id, label: service.name })
      )
      setServices(services)
      if (data?.service) {
        setUpdatingService({
          id: data.service.id,
          fieldId: data.fieldId,
          serviceId: data.service.serviceId
        })
      } else {
        setCreatingService({
          fieldId: data.fieldId,
          id: data.id
        })
      }
    } catch (error) {
      toast({
        status: 'error',
        description: 'Um erro inesperado aconteceu ao tentar buscar os dados!'
      })
    }
  }

  const handleSubmitField: SubmitHandler<FormFieldData> = async data => {
    try {
      await fieldFormSchema.validate(data, { abortEarly: false })
      await api.post(`/lawyer/profile/fields/${data.fieldId}`)

      refetch()
      setFieldIsOpen(false)
      toast({
        status: 'success',
        description: 'Área de atuação adicionada com sucesso!'
      })
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        formRef.current?.setErrors(formatYupError(error))
      } else {
        toast({
          status: 'error',
          description: 'Ocorreu um erro ao tentar criar uma área de atuação'
        })
      }
    }
  }

  const handleSubmitService: SubmitHandler<FormServiceData> = async data => {
    try {
      await serviceFormSchema.validate(data, { abortEarly: false })
      if (updatingService) {
        await api.put(
          `/lawyer/profile/services/${updatingService.id}/${data?.serviceId}`
        )
        setUpdatingService(undefined)
        toast({
          status: 'success',
          description: 'Serviço atualizado com sucesso!'
        })
      } else {
        await api.post(
          `/lawyer/profile/services/${creatingService?.id}/${data.serviceId}`
        )
        refetch()
        setFieldIsOpen(false)
        setCreatingService(undefined)
        toast({
          status: 'success',
          description: 'Serviço adicionado com sucesso!'
        })
      }

      setServices([])
      refetch()
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        formRef.current?.setErrors(formatYupError(error))
      } else {
        toast({
          status: 'error',
          description: 'Ocorreu um erro ao tentar criar ou atualizar o serviço'
        })
      }
    }
  }

  async function handleDeleteField(): Promise<void> {
    try {
      await api.delete(`/lawyer/profile/fields/delete/${deletingField}`)
      refetch()
      setDeletingField('')
      toast({
        status: 'success',
        description: 'Área de atuação removida com sucesso!'
      })
    } catch (error) {
      toast({
        status: 'error',
        description: 'Ocorreu um erro ao tentar excluir a área de atuação'
      })
    }
  }

  async function handleDeleteService(): Promise<void> {
    try {
      await api.delete(`/lawyer/profile/services/${deletingService}`)
      refetch()
      setDeletingService('')
      toast({
        status: 'success',
        description: 'Serviço removido com sucesso!'
      })
    } catch (error) {
      toast({
        status: 'error',
        description: 'Ocorreu um erro ao tentar excluir a área de atuação'
      })
    }
  }

  return (
    <>
      <ContentWrapper
        isLoading={!isLoading && isFetching}
        w="full"
        title="Áreas de atuação"
        infoLabel="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        headerChildren={
          <Button
            variant="link"
            color="green.300"
            onClick={() => setFieldIsOpen(true)}
          >
            Adicionar área de atuação
          </Button>
        }
      >
        <VStack align="flex-start" divider={<StackDivider />}>
          {error ? (
            <Text color="red.400" fontWeight="medium">
              Falha ao tentar buscar os suas áreas de atuação
            </Text>
          ) : isLoading ? (
            <Stack w="full" direction={['column', 'column', 'row']}>
              <Stack w="full" direction={['column', 'column', 'row']}>
                <Icon as={MdDeviceHub} color="green.300" w="7" h="7" mt="2" />
                <Box w="full">
                  <Stack
                    direction={['column', 'column', 'row']}
                    w="full"
                    justify="space-between"
                    mb="2"
                  >
                    <Skeleton w="180px" h="32px" />
                    <HStack>
                      <Skeleton w="130px" h="32px" />

                      <Skeleton w="32px" h="32px" />
                    </HStack>
                  </Stack>
                  <UnorderedList ml="0">
                    <ListItem
                      w="full"
                      d="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      gridGap="2"
                    >
                      <Skeleton w="100px" h="32px" />
                      <HStack>
                        <Skeleton w="40px" h="40px" />
                        <Skeleton w="40px" h="40px" />
                      </HStack>
                    </ListItem>
                  </UnorderedList>
                </Box>
              </Stack>
            </Stack>
          ) : data?.fields.length === 0 ? (
            <Text fontWeight="medium">
              Você não possui áreas de atuação cadastradas.
            </Text>
          ) : (
            data?.fields.map(field => (
              <Stack
                key={field.id}
                w="full"
                direction={['column', 'column', 'row']}
              >
                <Icon as={MdDeviceHub} color="green.300" w="7" h="7" mt="2" />
                <Box w="full">
                  <Stack
                    w="full"
                    justify="space-between"
                    direction={['column', 'column', 'row']}
                  >
                    <Text fontWeight="600" fontSize="xl">
                      {field.name}
                    </Text>
                    <Box>
                      <Button
                        variant="link"
                        color="green.300"
                        mr="2"
                        onClick={() =>
                          openServiceModal({
                            fieldId: field.slug,
                            id: field.id
                          })
                        }
                      >
                        Adicionar Serviço
                      </Button>
                      <IconButton
                        variant="unstyled"
                        fontSize="20"
                        color="red.400"
                        _hover={{ backgroundColor: 'gray.100' }}
                        icon={<Icon as={MdDelete} />}
                        aria-label={`Excluir área de atuação: ${field.name}`}
                        onClick={() => setDeletingField(field.id)}
                      />
                    </Box>
                  </Stack>

                  <UnorderedList ml="0">
                    {field?.services?.map(service => (
                      <ListItem
                        key={service.id}
                        maxW="full"
                        d="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        gridGap="2"
                      >
                        <Text maxW="360px">{service.name}</Text>

                        <Box>
                          <IconButton
                            mr="2"
                            variant="unstyled"
                            fontSize="20"
                            color="blue.300"
                            _hover={{ backgroundColor: 'gray.100' }}
                            icon={<Icon as={MdEdit} />}
                            aria-label={`Editar serviço: ${service.name}`}
                            onClick={() =>
                              openServiceModal({
                                fieldId: field.slug,
                                id: field.id,
                                service: {
                                  id: service.id,
                                  serviceId: service.serviceId
                                }
                              })
                            }
                          />
                          <IconButton
                            variant="unstyled"
                            fontSize="20"
                            color="red.400"
                            _hover={{ backgroundColor: 'gray.100' }}
                            icon={<Icon as={MdDelete} />}
                            aria-label={`Excluir serviço: ${field.name}`}
                            onClick={() => setDeletingService(service.id)}
                          />
                        </Box>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
              </Stack>
            ))
          )}
        </VStack>
      </ContentWrapper>
      <Modal
        isOpen={fieldIsOpen}
        title="Área de atuação"
        onClose={() => setFieldIsOpen(false)}
      >
        <Form
          ref={formRef}
          onSubmit={handleSubmitField}
          d="flex"
          flexDirection="column"
          alignItems="flex-end"
        >
          <Select
            name="fieldId"
            label="Área de atuação"
            options={formattedFields}
            placeholder="Selecione uma área de atuação"
          />
          <Button
            colorScheme="yellow"
            mt="4"
            ml="auto"
            onClick={() => formRef.current?.submitForm()}
          >
            Adicionar
          </Button>
        </Form>
      </Modal>
      <Modal
        isOpen={services.length > 0 && (!!creatingService || !!updatingService)}
        title="Serviço"
        onClose={() =>
          updatingService
            ? setUpdatingService(undefined)
            : setCreatingService(undefined)
        }
      >
        <Form
          ref={formRef}
          onSubmit={handleSubmitService}
          initialData={updatingService}
          d="flex"
          flexDirection="column"
          alignItems="flex-end"
        >
          <Select
            name="serviceId"
            label="Serviço"
            options={services}
            placeholder="Selecione um serviço"
          />
          <Button
            colorScheme="yellow"
            mt="4"
            ml="auto"
            onClick={() => formRef.current?.submitForm()}
          >
            {updatingService ? 'Atualizar' : 'Adicionar'}
          </Button>
        </Form>
      </Modal>
      <ConfirmationModal
        isOpen={!!deletingService || !!deletingField}
        onClose={() =>
          deletingField ? setDeletingField('') : setDeletingService('')
        }
        onAccept={() =>
          deletingField ? handleDeleteField() : handleDeleteService()
        }
        title={deletingField ? 'Excluir área de atuação' : 'Excluir serviço'}
      />
    </>
  )
}
