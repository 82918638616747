import { ChangeEvent, useRef, useState } from 'react'
import { IoMapOutline } from 'react-icons/io5'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'

import {
  Box,
  Button,
  Stack,
  Icon,
  Text,
  Flex,
  Divider,
  Skeleton,
  useToast
} from '@chakra-ui/react'
import { FormHandles, SubmitHandler } from '@unform/core'
import axios, { AxiosError } from 'axios'
import * as yup from 'yup'

import { useCan } from '../../hooks/useCan'
import { getCities } from '../../hooks/useCities'
import { useStates } from '../../hooks/useState'
import { api } from '../../services/api'
import { formatErrorMessage } from '../../utils/formatErrorMessage'
import { formatYupError } from '../../utils/formatYupError'
import { ConfirmationModal } from '../ConfirmationModal'
import { Form } from '../Form/Form'
import { Option, Select } from '../Form/Select'
import { Modal } from '../Modal'
import { ServiceItem } from './ServiceItem'

type Location = {
  id: string
  name: string
}

type FormData = {
  stateId: string
  cityId: string
}

type UsePresentialServiceResult = {
  services: Location[]
}

export function usePresentialServices(
  options?: UseQueryOptions<UsePresentialServiceResult, AxiosError>
): UseQueryResult<UsePresentialServiceResult, AxiosError> {
  return useQuery<UsePresentialServiceResult, AxiosError>(
    ['me-presential-services'],
    async () => {
      const { data } = await api.get<Location[]>(
        `/lawyer/profile/attendance/presential`
      )

      return { services: data }
    },
    {
      staleTime: 1000 * 60 * 1, // One minutes
      ...options
    }
  )
}

const presentialServiceFormSchema = yup.object({
  stateId: yup.string().required('Você deve selecionar o estado'),
  cityId: yup.string().required('Você deve selecionar a cidade')
})

export function PresentialService(): JSX.Element {
  const { data, isLoading, error, refetch } = usePresentialServices()
  const [isOpen, setIsOpen] = useState(false)
  const [deletingPresentialService, setDeletingPresentialService] = useState('')
  const formRef = useRef<FormHandles>(null)
  const can = useCan('fields', data?.services)
  const [cities, setCities] = useState<Option[]>([])
  const { data: options } = useStates()

  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: 'top-right'
  })

  const handleSubmit: SubmitHandler<FormData> = async data => {
    try {
      await presentialServiceFormSchema.validate(data, { abortEarly: false })
      await api.post(`/lawyer/profile/attendance/presential/${data.cityId}`)
      refetch()
      setIsOpen(false)
      toast({
        status: 'success',
        description: 'Atendimento presencial criado com sucesso!'
      })
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        formRef.current?.setErrors(formatYupError(error))
      } else {
        toast({
          status: 'error',
          description:
            'Ocorreu um erro ao tentar criar um atendimento presencial'
        })
      }
    }
  }

  async function handleDeletePresentialService(): Promise<void> {
    try {
      await api.delete(
        `/lawyer/profile/attendance/presential/${deletingPresentialService}`
      )
      refetch()
      setDeletingPresentialService('')
      toast({
        status: 'success',
        description: 'Atendimento presencial removido com sucesso!'
      })
    } catch (error) {
      toast({
        status: 'error',
        description: 'Ocorreu um erro ao excluir um atendimento presencial!'
      })
    }
  }

  async function handleSelectState(
    event: ChangeEvent<HTMLSelectElement>
  ): Promise<void> {
    try {
      const id = event.target.value
      if (id) {
        const { cities } = await getCities(id)
        setCities(cities)
      } else {
        setCities([])
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast({
          status: 'error',
          description: formatErrorMessage(error)
        })
      }
    }
  }

  function handleOpenModal(): void {
    if (can) {
      setIsOpen(true)
    } else {
      toast({
        status: 'warning',
        title: 'Você não tem permissão!',
        description: 'Seu plano limita a criação de atendimentos presenciais.'
      })
    }
  }

  return (
    <>
      <Box>
        <Stack direction={['column', 'column', 'row']} align="start">
          <Icon as={IoMapOutline} color="green.300" w="9" h="9" p="1" />
          <Box w="full">
            <Flex
              justify="space-between"
              align={['flex-start', 'flex-start', 'center']}
              direction={['column', 'column', 'row']}
            >
              <Text as="h4" fontSize="xl">
                Atendimento presencial
              </Text>
              <Button
                variant="link"
                colorScheme="green"
                fontWeight="medium"
                onClick={handleOpenModal}
              >
                Adicionar local
              </Button>
            </Flex>
            <Divider my="4" />
            <Flex flexWrap="wrap" gridGap="4">
              {error ? (
                <Text color="red.400" fontWeight="medium">
                  Não conseguimos carregar seus dados
                </Text>
              ) : !isLoading && data ? (
                data.services.length === 0 ? (
                  <Text fontWeight="medium">
                    Você não possui atendimentos presenciais cadastrados.
                  </Text>
                ) : (
                  data.services?.map(item => (
                    <ServiceItem
                      key={item.id}
                      label={item.name}
                      onDelete={() => setDeletingPresentialService(item.id)}
                    />
                  ))
                )
              ) : (
                <>
                  <Skeleton w="140px" h="32px" />
                  <Skeleton w="140px" h="32px" />
                  <Skeleton w="140px" h="32px" />
                </>
              )}
              {}
            </Flex>
          </Box>
        </Stack>
      </Box>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title="Atendimento presencial"
      >
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          d="flex"
          flexDirection="column"
          alignItems="flex-end"
        >
          <Select
            name="stateId"
            label="Estado"
            options={options?.states || []}
            onChange={handleSelectState}
            placeholder="Selecione um estado"
          />
          <Select
            name="cityId"
            label="Cidade"
            options={cities}
            placeholder="Selecione um cidade"
          />
          <Button
            mt="4"
            colorScheme="yellow"
            onClick={() => formRef.current?.submitForm()}
          >
            Cadastrar
          </Button>
        </Form>
      </Modal>
      <ConfirmationModal
        title="Excluir atendimento presencial?"
        isOpen={!!deletingPresentialService}
        onClose={() => setDeletingPresentialService('')}
        onAccept={handleDeletePresentialService}
      />
    </>
  )
}
