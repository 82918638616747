import { useState } from 'react'

import {
  Button,
  Text,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalProps
} from '@chakra-ui/react'

type ConfirmationModalProps = Omit<ModalProps, 'children'> & {
  title: string
  message?: string
  onAccept: () => Promise<void>
}

export function ConfirmationModal({
  title,
  message,
  onClose,
  onAccept,
  ...rest
}: ConfirmationModalProps): JSX.Element {
  const [isLoading, setIsLoading] = useState(false)
  async function handleAccept(): Promise<void> {
    setIsLoading(true)
    await onAccept()
    setIsLoading(false)
  }

  return (
    <Modal onClose={onClose} {...rest}>
      <ModalOverlay />
      <ModalContent my="auto" mx="4">
        <ModalBody py="4">
          <Heading fontWeight="medium" fontSize="2xl" mb="2">
            {title}
          </Heading>
          <Text>
            {message || 'Você tem certeza? Essa ação não pode ser revertida.'}
          </Text>
          <HStack w="min" mt="6" ml="auto" spacing="4">
            <Button colorScheme="gray" onClick={onClose}>
              Cancelar
            </Button>
            <Button
              colorScheme="red"
              onClick={handleAccept}
              isLoading={isLoading}
            >
              Excluir
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
