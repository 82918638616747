import { memo, useRef, useState } from 'react'
import { IoMdTrash } from 'react-icons/io'
import { IoCard } from 'react-icons/io5'
import { MdEdit } from 'react-icons/md'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'

import {
  Flex,
  Icon,
  SimpleGrid,
  Text,
  useToast,
  Skeleton,
  IconButton,
  Center
} from '@chakra-ui/react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { AxiosError, AxiosRequestConfig } from 'axios'
import * as yup from 'yup'

import { CleanModal } from '../../components/CleanModal'
import { ConfirmationModal } from '../../components/ConfirmationModal'
import { Form } from '../../components/Form/Form'
import { Input } from '../../components/Form/Input'
import { FormContainer } from '../../components/FormContainer'
import { api } from '../../services/api'
import { encryptCard } from '../../utils/encryptCard'
import { formatYupError } from '../../utils/formatYupError'
import { hideCardNumber } from '../../utils/masks'
import { cardFormSchema } from './validationSchema'

type Card = {
  id: number
  number: string | null
}
type CardResponse = {
  id: number
  pagarMeCardNumber: string | null
}

export type GetCardsResponse = {
  cards: Card[]
}

export async function getCards(
  config?: AxiosRequestConfig
): Promise<GetCardsResponse> {
  const { data } = await api.get<CardResponse>(`/lawyer/settings/card`, {
    ...config
  })

  return {
    cards: data.pagarMeCardNumber
      ? [
          {
            id: data.id,
            number: hideCardNumber(data.pagarMeCardNumber || '')
          }
        ]
      : []
  }
}

export function useCards(
  options?: UseQueryOptions<GetCardsResponse, AxiosError>
): UseQueryResult<GetCardsResponse, AxiosError> {
  return useQuery<GetCardsResponse, AxiosError>(['cards'], () => getCards(), {
    staleTime: 1000 * 60 * 5, // Five minutes
    ...options
  })
}

type FormData = {
  number: string
  name: string
  cvv: string
  expiry: string
}

export function WayPaymentRaw(): JSX.Element {
  const formRef = useRef<FormHandles>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [removingCard, setRemovingCard] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const { data, isLoading, error, refetch } = useCards()

  const toast = useToast({
    position: 'top-right',
    duration: 5000,
    isClosable: true
  })

  async function handleDeleteCard(): Promise<void> {
    try {
      await api.patch(`lawyer/plans/unsubscribe`)
      setRemovingCard(false)
      toast({
        status: 'success',
        description: 'Plano atualizado com sucesso!.'
      })
      refetch()
    } catch (error) {
      toast({
        status: 'error',
        description: 'Ocorreu um erro ao alterar o plano.'
      })
    }
  }

  const handleSubmit: SubmitHandler<FormData> = async data => {
    setLoading(true)
    try {
      const res = await cardFormSchema.validate(data, { abortEarly: false })
      const card_hash = await encryptCard({
        card_number: res.number,
        card_holder_name: res.name,
        card_expiration_date: res.expiry,
        card_cvv: res.cvv
      })
      await api.put(`lawyer/card/update?cardHash=${card_hash}`)
      toast({
        status: 'success',
        title: 'Sucesso!',
        description: 'Cartão atualizado com sucesso!.'
      })
      refetch()
      setIsOpen(false)
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        formRef.current?.setErrors(formatYupError(error))
      } else {
        toast({
          status: 'error',
          description: 'Ocorreu um erro ao atualizar o cartão.'
        })
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <FormContainer headerText="Forma de Pagamento" offButton isMinContent>
        {error ? (
          <Center p={4}>
            <Text color="red.400" fontWeight="medium">
              Não conseguimos carregar seus dados de cartões.
            </Text>
          </Center>
        ) : isLoading ? (
          <Flex
            w="full"
            my={5}
            flexDir="row"
            justifyContent="space-between"
            alignItems="center"
            gap="1"
          >
            <Skeleton w="120px" h="7" />
            <Flex gridGap="1">
              <Skeleton w="10" h="10" />
              <Skeleton w="10" h="10" />
            </Flex>
          </Flex>
        ) : data?.cards.length === 0 ? (
          <Center p={4}>
            <Text>Você ainda não possui nenhuma forma de pagamento</Text>
          </Center>
        ) : (
          data?.cards.map(card => (
            <Flex
              key={card.id}
              flexDir="row"
              w="full"
              justifyContent="space-between"
              alignItems="center"
              py={5}
            >
              <Flex>
                <Icon color="green.300" w={5} h={5} as={IoCard} mr={2} />
                <Text>{`●●●● ${card.number}`}</Text>
              </Flex>
              <Flex gridGap="2">
                <IconButton
                  icon={<Icon as={MdEdit} />}
                  color="blue.300"
                  fontSize="22px"
                  bg="none"
                  _hover={{ bg: 'gray.100' }}
                  aria-label="Editar cartão"
                  onClick={() => setIsOpen(true)}
                />
                <IconButton
                  icon={<Icon as={IoMdTrash} />}
                  color="red.300"
                  fontSize="22px"
                  bg="none"
                  _hover={{ bg: 'gray.100' }}
                  aria-label="Excluir cartão"
                  onClick={() => setRemovingCard(true)}
                />
              </Flex>
            </Flex>
          ))
        )}
      </FormContainer>
      <ConfirmationModal
        isOpen={!!removingCard}
        title="Excluir cartão e cancelar seu plano?"
        message="Ao excluir seu cartão, seu plano será automaticamente mudado para gratuito!"
        onClose={() => setRemovingCard(false)}
        onAccept={handleDeleteCard}
      />
      <CleanModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <FormContainer
            headerText="Dados do Cartão"
            buttonText="Salvar"
            loading={loading}
          >
            <Input
              name="name"
              label="Nome Impresso no  Cartão(obrigatório)"
              placeholder="Informe o nome impresso no cartão"
              mb="4"
            />
            <Input
              name="number"
              label="Número do Cartão (obrigatório)"
              placeholder="Informe o número do cartão"
              mask="creditCard"
              mb="4"
            />
            <SimpleGrid columns={[2]} w="full" spacingX="15px">
              <Input
                name="expiry"
                label="Validade (obrigatório)"
                placeholder="Validade (obrigatório)"
                mask="expiryDate"
                mb="4"
              />
              <Input
                name="cvv"
                label="CVV (obrigatório)"
                placeholder="Cód. Segurança"
                mask="number"
                mb="4"
              />
            </SimpleGrid>
          </FormContainer>
        </Form>
      </CleanModal>
    </>
  )
}

export const WayPayment = memo(WayPaymentRaw)
