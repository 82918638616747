import { memo, ReactNode } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Icon, Link as ChakraLink, LinkProps, Text, As } from '@chakra-ui/react'

interface ActiveLinkProps extends LinkProps {
  icon: As<unknown>
  children: ReactNode
  shouldMatchExactHref?: boolean
  href: string
}

function ActiveLinkRaw({
  icon,
  shouldMatchExactHref = false,
  children,
  href,
  ...rest
}: ActiveLinkProps): JSX.Element {
  const { pathname } = useLocation()
  let isActive = false

  if (shouldMatchExactHref && pathname === href) isActive = true
  if (!shouldMatchExactHref && pathname.startsWith(String(href)))
    isActive = true

  return (
    <ChakraLink
      as={Link}
      to={href}
      w="full"
      display="flex"
      pl={['8', '8', '5']}
      py="2"
      bg={isActive ? 'gray.600' : 'inherit'}
      align="center"
      pos="relative"
      _after={
        isActive
          ? {
              content: '""',
              w: '4px',
              h: 'full',
              bg: 'yellow.300',
              pos: 'absolute',
              top: 0,
              left: 0
            }
          : {}
      }
      {...rest}
    >
      <Icon as={icon} fontSize="28" />
      <Text ml="4" fontWeight="medium">
        {children}
      </Text>
    </ChakraLink>
  )
}

export const ActiveLink = memo(ActiveLinkRaw)
