import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'

import { AxiosError, AxiosRequestConfig } from 'axios'

import { api } from '../services/api'

export type Service = {
  id: string
  name: string
  field: string
}

export type GetServicesResponse = {
  services: Service[]
}

export async function getServices(
  field: string,
  config?: AxiosRequestConfig
): Promise<GetServicesResponse> {
  const { data } = await api.get(`/services/${field}`, {
    ...config
  })

  return { services: data }
}

export function useServices(
  field: string,
  options?: UseQueryOptions<GetServicesResponse, AxiosError>
): UseQueryResult<GetServicesResponse, AxiosError> {
  return useQuery<GetServicesResponse, AxiosError>(
    ['services', field],
    () => getServices(field),
    {
      staleTime: 1000 * 60 * 5, // Five minutes
      ...options
    }
  )
}
