/* eslint-disable jsx-a11y/anchor-is-valid */
import { MdChatBubble, MdHome, MdPerson, MdSettings } from 'react-icons/md'
import { RiVipCrownFill, RiLogoutBoxRFill } from 'react-icons/ri'

import { Stack, Icon, Text, Button } from '@chakra-ui/react'

import { useAuth } from '../../hooks/useAuth'
import { ActiveLink } from '../ActiveLink'
import { NavSection } from './NavSection'

export function SidebarNav(): JSX.Element {
  const { signOut } = useAuth()
  return (
    <Stack spacing="8" mt="6" align="flex-start">
      <NavSection title="GERAL">
        <ActiveLink icon={MdHome} href="/home">
          início
        </ActiveLink>
        <ActiveLink icon={MdPerson} href="/me">
          Meu Perfil
        </ActiveLink>
        <ActiveLink icon={MdChatBubble} href="/assessments">
          Avaliações
        </ActiveLink>
        <ActiveLink icon={RiVipCrownFill} href="/plans">
          Planos
        </ActiveLink>
      </NavSection>
      <NavSection title="PERFIL">
        <ActiveLink icon={MdSettings} href="/settings">
          Configurações
        </ActiveLink>
        <Button
          variant="unstyled"
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          onClick={() => signOut()}
          pl={['8', '8', '5']}
        >
          <Icon as={RiLogoutBoxRFill} fontSize="28" />
          <Text ml="4" fontWeight="medium">
            Sair
          </Text>
        </Button>
      </NavSection>
    </Stack>
  )
}
