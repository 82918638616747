import { ChangeEvent, useEffect, useState } from 'react'
import { Link as LinkRouter } from 'react-router-dom'

import {
  Box,
  Center,
  Grid,
  Flex,
  GridItem,
  Heading,
  Link,
  Select,
  Spinner,
  Text
} from '@chakra-ui/react'

import { FormContainer } from '../components/FormContainer'
import { PageTemplate } from '../components/PageTemplate'
import { ProfileSteps, StepProps } from '../components/ProfileSteps'
import { Rating, RatingProps } from '../components/Rating'
import { SkeletonRating } from '../components/SkeletonRating'
import { CardProps, SmallCard } from '../components/SmallCard'
import { SmallCardSkeleton } from '../components/SmallCardSkeleton'
import { StepsSkeleton } from '../components/StepsSkeleton'
import { useAuth } from '../hooks/useAuth'
import { api } from '../services/api'

const filterOptions = [
  {
    label: 'Últimos 7 dias',
    value: 7
  },
  {
    label: 'Últimos 15 dias',
    value: 15
  },
  {
    label: 'Últimos 30 dias',
    value: 30
  },
  {
    label: 'Últimos 60 dias',
    value: 60
  },
  {
    label: 'Últimos 90 dias',
    value: 90
  },
  {
    label: 'Ultimo Ano',
    value: 365
  }
]

export function Home(): JSX.Element {
  const [cardValues, setCardValues] = useState<CardProps[]>()
  const [stepsValues, setStepsValues] = useState<StepProps[]>()
  const [ratingValues, setRatingValues] = useState<RatingProps[]>()
  const [filterPeriod, setFilterPeriod] = useState<string>('7')
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { user } = useAuth()

  useEffect(() => {
    async function fetchCardData(): Promise<void> {
      try {
        const { data } = await api.get(
          `/lawyer/dashboard/summary?days=${filterPeriod}`
        )
        setCardValues(data)
      } catch {
        setError(true)
      }
    }
    fetchCardData()
  }, [filterPeriod])

  useEffect(() => {
    async function fetchData(): Promise<void> {
      try {
        setLoading(true)
        const response = await api.get(`/lawyer/dashboard/profile`)
        const rating = await api.get(`lawyer/ratings?page=1&limit=3`)
        setRatingValues(rating.data)
        setStepsValues(response.data)
      } catch (error) {
        setError(true)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  function handlePeriodChange(event: ChangeEvent<HTMLSelectElement>): void {
    return setFilterPeriod(event.target.value)
  }

  return (
    <PageTemplate>
      <Flex h="min-content" w="full" p="30" d="flex" direction="column">
        <Box d="flex" justifyContent="space-between" w="full">
          <Heading
            fontWeight={400}
            fontSize={22}
          >{`Olá ${user?.fullName}, seja bem vindo(a)!`}</Heading>
        </Box>
        <Flex w={240} m="16px 0" flexDir="row" alignItems="center">
          <Select
            variant="outline"
            w={['full', 180]}
            size="lg"
            value={filterPeriod}
            onChange={handlePeriodChange}
          >
            {filterOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
          {loading && (
            <Spinner
              ml="4"
              size="md"
              thickness="2px"
              speed="0.65s"
              emptyColor="gray.700"
            />
          )}
        </Flex>
        {error ? (
          <Text alignSelf="center" marginTop={20}>
            Falha ao obter as estatísticas.
          </Text>
        ) : (
          <Grid
            templateColumns={['1fr', '0.5fr 3fr', '0.5fr 2fr 2fr']}
            gap="8"
            mt={4}
          >
            <GridItem d={'flex'} alignItems={['center', '']} flexDir={'column'}>
              {loading
                ? [...Array(3)].map((_value, index) => (
                    <SmallCardSkeleton key={index} />
                  ))
                : cardValues?.map(value => (
                    <SmallCard key={value.id} card={value} />
                  ))}
            </GridItem>
            <GridItem>
              {loading ? (
                <StepsSkeleton />
              ) : (
                stepsValues && (
                  <FormContainer
                    headerText={'Meu Perfil'}
                    offButton
                    isMinContent
                    paddingBottom={'40px'}
                  >
                    {stepsValues.map(step => (
                      <ProfileSteps key={step.id} step={step} />
                    ))}
                  </FormContainer>
                )
              )}
            </GridItem>
            <GridItem colSpan={[1, 2, 1]}>
              <Heading fontSize={18} mb={5}>
                Últimas Avaliações
              </Heading>
              {loading ? (
                [...Array(3)].map((_value, index) => (
                  <SkeletonRating key={index} />
                ))
              ) : ratingValues && ratingValues.length > 0 ? (
                <>
                  {ratingValues.map(rating => (
                    <Rating key={rating.id} rating={rating} />
                  ))}
                  <Center>
                    <Link
                      as={LinkRouter}
                      textDecor={'underline'}
                      color={'gray.600'}
                      to="assessments"
                    >
                      Ver Tudo
                    </Link>
                  </Center>
                </>
              ) : (
                <Center p={10}>
                  <Text>Você ainda não possui nenhuma avaliação</Text>
                </Center>
              )}
            </GridItem>
          </Grid>
        )}
      </Flex>
    </PageTemplate>
  )
}
