import { Center, Spinner } from '@chakra-ui/react'

export function Loading(): JSX.Element {
  return (
    <Center w="full" h="100vh">
      <Spinner
        color="gray.800"
        w="64px"
        h="64px"
        thickness="6px"
        speed="0.65s"
        emptyColor="gray.200"
      />
    </Center>
  )
}
