/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router'

import {
  Flex,
  useBreakpointValue,
  Box,
  Button,
  HStack,
  Heading,
  Divider,
  Grid,
  Skeleton,
  useToast,
  VStack,
  Stack
} from '@chakra-ui/react'
import { FormHandles, Scope, SubmitHandler } from '@unform/core'
import axios from 'axios'
import * as yup from 'yup'

import { Form } from '../components/Form/Form'
import { Input } from '../components/Form/Input'
import { PageTemplate } from '../components/PageTemplate'
import { PlanDescription } from '../components/PlanCard'
import { useAuth } from '../hooks/useAuth'
import { usePlan } from '../hooks/usePlan'
import { PlanSlug } from '../hooks/usePlans'
import { api } from '../services/api'
import { encryptCard } from '../utils/encryptCard'
import { formatYupError } from '../utils/formatYupError'
import { validateCpfCnpj } from '../utils/validations'

type FormData = {
  customer: {
    name: string
    email: string
    cpfCnpj: string
    birthDate: string
  }
  address: {
    zipcode: string
    street: string
    number: string
    complement: string
    city: string
    state: string
  }
  card: {
    name: string
    number: string
    expiry: string
    cvv: string
  }
}

const planFormSchema = yup.object({
  customer: yup.object({
    name: yup.string().required('Nome é obrigatório.'),
    email: yup
      .string()
      .email('E-mail inválido.')
      .required('E-mail é obrigatório.'),
    cpfCnpj: yup
      .string()
      .test('Validate cpf or cnpj', 'CPF/CNPJ é inválido', validateCpfCnpj)
      .required('CPF/CNPJ é obrigatório.'),
    bornAt: yup.string().required('Data de nascimento é obrigatório.')
  }),
  address: yup.object({
    zipcode: yup.string().required('CEP é obrigatório.'),
    street: yup.string().required('Rua é obrigatório.'),
    neighborhood: yup.string().required('Bairro é obrigatório.'),
    streetNumber: yup.string().required('Número é obrigatório.')
  }),
  card: yup.object({
    name: yup.string().required('Nome no cartão é obrigatório.'),
    number: yup
      .string()
      .min(12, 'Número do cartão inválido.')
      .min(16, 'Número do cartão inválido.')
      .required('Número do cartão é obrigatório.'),
    expiry: yup
      .string()
      .min(5, 'Data de expiração inválida')
      .max(5, 'Data de expiração inválida')
      .required('Data de expiração é obrigatório.'),
    cvv: yup.string().required('CVV é obrigatório')
  })
})

export function Plan(): JSX.Element {
  const { slug } = useParams<{ slug: PlanSlug }>()
  const { data } = usePlan({ plan: slug })
  const { user, updateUser } = useAuth()
  const formRef = useRef<FormHandles>(null)
  const history = useHistory()
  const isDrawerSidebar = useBreakpointValue({
    base: true,
    lg: false
  })

  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: 'top-right'
  })

  useEffect(() => {
    if (user?.hasCard) {
      history.push('/plans')
    }
  }, [user, history])

  const handleSubmit: SubmitHandler<FormData> = async data => {
    try {
      const res = await planFormSchema.validate(data, { abortEarly: false })
      const cardHash = await encryptCard({
        card_number: res.card.number.replaceAll(' ', ''),
        card_holder_name: res.card.name,
        card_expiration_date: res.card.expiry.replaceAll('/', ''),
        card_cvv: res.card.cvv
      })

      await api.post(`/lawyer/plans/subscribe`, {
        plan: slug,
        cardHash,
        customer: {
          ...res.customer,
          address: res.address
        }
      })
      updateUser({
        hasCard: true,
        plan: slug
      })
      toast({
        status: 'success',
        description: 'Plano assinado com sucesso.'
      })
      history.push('/home')
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        formRef.current?.setErrors(formatYupError(error))
      } else if (axios.isAxiosError(error)) {
        toast({
          status: 'error',
          description: error.response?.data?.message
        })
      } else {
        toast({
          status: 'error',
          description: 'Erro ao tentar encriptar seu cartão'
        })
      }
    }
  }

  return (
    <PageTemplate>
      <Box w="full" maxW={1200} p="4" pb="8" pt={['4', '4', '60px']} mx="auto">
        <HStack justify="space-between" align="center">
          <Heading as="h1">Pagamento</Heading>
          <Button
            variant="unstyled"
            d="block"
            mt="20px"
            ml="auto"
            h="7"
            fontWeight="normal"
            _hover={{
              textDecoration: 'underline'
            }}
            onClick={() => history.push('/plans')}
          >
            Voltar
          </Button>
        </HStack>
        <Grid
          pt="8"
          gridTemplateAreas={isDrawerSidebar ? '"plan" "form"' : '"form plan"'}
          justify="space-between"
          gridGap="8"
        >
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            w="full"
            maxW={isDrawerSidebar ? 'full' : '600px'}
            gridArea="form"
          >
            <Box boxShadow="md" borderRadius="8" px="6" py="5" pb="10" mb="9">
              <Heading as="h2" size="md">
                Dados de cobrança
              </Heading>
              <Divider my="4" mb="3" />
              <Scope path="customer">
                <Input
                  name="name"
                  label="Nome completo/Razão social (obrigatório)"
                  placeholder="Informe seu nome ou razão social"
                  mb="4"
                />
                <Input
                  name="email"
                  label="E-mail (obrigatório)"
                  placeholder="Informe seu e-mail de cobrança"
                  mb="4"
                />
                <Input
                  name="cpfCnpj"
                  mask="cpfCnpj"
                  label="CPF/CNPJ (obrigatório)"
                  placeholder="Informe seu CPF ou CNPJ"
                  mb="4"
                />
                <Input
                  name="bornAt"
                  label="Data de nascimento (obrigatório)"
                  type="date"
                />
              </Scope>
            </Box>
            <Box boxShadow="md" borderRadius="8" px="6" py="5" pb="10" mb="9">
              <Heading as="h2" size="md">
                Endereço de cobrança
              </Heading>
              <Divider my="4" mb="3" />
              <Scope path="address">
                <Input
                  name="zipcode"
                  label="CEP (obrigatório)"
                  placeholder="Informe seu CEP"
                  mask="cep"
                  mb="4"
                />
                <Input
                  name="street"
                  label="Rua (obrigatório)"
                  placeholder="Informe sua rua"
                  mb="4"
                />
                <Input
                  name="neighborhood"
                  label="Bairro (obrigatório)"
                  placeholder="Informe seu bairro"
                  mb="4"
                />
                <Input
                  name="streetNumber"
                  label="Número (obrigatório)"
                  placeholder="Informe o número"
                  mb="4"
                />
              </Scope>
            </Box>
            <Box boxShadow="md" borderRadius="8" px="6" py="5" pb="10" mb="9">
              <Heading as="h2" size="md">
                Dados do cartão
              </Heading>
              <Divider my="4" mb="3" />
              <Scope path="card">
                <Input
                  name="name"
                  label="Nome impresso no cartão (obrigatório)"
                  placeholder="Informe seu nome impresso no cartão"
                  mb="4"
                />
                <Input
                  name="number"
                  label="Número do cartão (obrigatório)"
                  placeholder="Informe o número do cartão"
                  mask="creditCard"
                  mb="4"
                />
                <Flex gridGap="4" mb="4">
                  <Input
                    name="expiry"
                    label="Validade (obrigatório)"
                    placeholder="mm/aa"
                    mask="expiryDate"
                  />
                  <Input
                    name="cvv"
                    label="CVV (obrigatório)"
                    placeholder="Cod. segurança"
                    type="number"
                  />
                </Flex>
              </Scope>
            </Box>
            <Button type="submit" colorScheme="yellow" w="full">
              Assinar plano
            </Button>
          </Form>
          <Box
            w="full"
            h="min"
            maxW={isDrawerSidebar ? 'full' : '384px'}
            boxShadow="md"
            borderRadius="8"
            px="4"
            py="8"
            justify="space-between"
            align="center"
            gridArea="plan"
          >
            {!data ? (
              <>
                <Skeleton w="260px" h="38px" />
                <Divider my="4" />
                <Stack pt="10" spacing="2" align="center">
                  <Skeleton w="220px" h="22px" />
                  <Skeleton w="180px" h="16px" />
                  <Skeleton w="160px" h="16px" />
                </Stack>
                <Stack mt="6" spacing="2" align="center">
                  <Skeleton w="220px" h="22px" />
                  <Skeleton w="180px" h="16px" />
                  <Skeleton w="160px" h="16px" />
                  <Skeleton w="160px" h="16px" />
                </Stack>
              </>
            ) : (
              <>
                <Heading as="h2">{data?.plan.name}</Heading>
                <Divider my="4" />
                {data?.plan.slug === 'silver' ? (
                  <VStack gridGap="2">
                    <PlanDescription
                      title="Resultado nas Buscas"
                      text="Acima do Plano Gratuito e abaixo do Plano Premium"
                    />
                    <PlanDescription title="Áreas de Atuação" text="2 áreas" />
                    <PlanDescription title="Localidades" text="4 localidades" />
                    <PlanDescription
                      title="Whatsapp"
                      text="Exibição do número"
                    />
                    <PlanDescription title="Perfil" text="Ilimitado" />
                    <PlanDescription
                      title="Destaque no Perfil"
                      text="Tag do Plano"
                    />
                  </VStack>
                ) : (
                  <VStack gridGap="2">
                    <PlanDescription
                      title="Resultado nas Buscas"
                      text="Acima do Plano Gratuito e do Plano Básico"
                    />
                    <PlanDescription
                      title="Áreas de Atuação"
                      text="Ilimitado"
                    />
                    <PlanDescription title="Localidades" text="Ilimitado" />
                    <PlanDescription
                      title="Whatsapp"
                      text="Botão para Contato direto"
                    />
                    <PlanDescription title="Perfil" text="Ilimitado" />
                    <PlanDescription
                      title="Destaque no Perfil"
                      text="Destaque na Foto de Perfil e Tag do Plano"
                    />
                  </VStack>
                )}
              </>
            )}
          </Box>
        </Grid>
      </Box>
    </PageTemplate>
  )
}
