import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'

import { AxiosError, AxiosRequestConfig } from 'axios'

import { api } from '../services/api'

export type Field = {
  id: string
  name: string
}

export type GetFieldsResponse = {
  fields: Field[]
}

export async function getFields(
  config?: AxiosRequestConfig
): Promise<GetFieldsResponse> {
  const { data } = await api.get('/fields', {
    ...config
  })

  return { fields: data }
}

export function useFields(
  options?: UseQueryOptions<GetFieldsResponse, AxiosError>
): UseQueryResult<GetFieldsResponse, AxiosError> {
  return useQuery<GetFieldsResponse, AxiosError>(
    ['fields'],
    () => getFields(),
    {
      staleTime: 1000 * 60 * 5, // Five minutes
      ...options
    }
  )
}
