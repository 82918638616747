import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'

import { AxiosError, AxiosRequestConfig } from 'axios'

import { Option } from '../components/Form/Select'
import { api } from '../services/api'

export type City = {
  id: string
  name: string
}

export type GetCitiesResponse = {
  cities: Option[]
}

export async function getCities(
  state: string,
  config?: AxiosRequestConfig
): Promise<GetCitiesResponse> {
  const { data } = await api.get<City[]>(`/cities/${state}`, {
    ...config
  })

  const formattedCities = data.map(state => ({
    value: state.id,
    label: state.name
  }))

  return { cities: formattedCities }
}

export function useCities(
  state: string,
  options?: UseQueryOptions<GetCitiesResponse, AxiosError>
): UseQueryResult<GetCitiesResponse, AxiosError> {
  return useQuery<GetCitiesResponse, AxiosError>(
    ['cities', state],
    () => getCities(state),
    {
      staleTime: 1000 * 60 * 30, // Thirty minutes
      ...options
    }
  )
}
