import { ChangeEvent, useRef, useState } from 'react'
import { IoLocation } from 'react-icons/io5'
import { MdDelete, MdEdit } from 'react-icons/md'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'

import {
  Box,
  Stack,
  Icon,
  Text,
  Flex,
  Skeleton,
  IconButton,
  VStack,
  StackDivider,
  useToast,
  Button
} from '@chakra-ui/react'
import { FormHandles, Scope, SubmitHandler } from '@unform/core'
import axios, { AxiosError } from 'axios'
import * as yup from 'yup'

import { useCan } from '../../hooks/useCan'
import { getCities } from '../../hooks/useCities'
import { useStates } from '../../hooks/useState'
import { api } from '../../services/api'
import { formatErrorMessage } from '../../utils/formatErrorMessage'
import { formatYupError } from '../../utils/formatYupError'
import {
  getAddressFromCEP,
  GetAddressFromCEPError
} from '../../utils/getAddressFromCEP'
import { UFs } from '../../utils/validations'
import { ConfirmationModal } from '../ConfirmationModal'
import { ContentWrapper } from '../ContentWrapper'
import { Form } from '../Form/Form'
import { Input } from '../Form/Input'
import { Option, Select } from '../Form/Select'
import { Modal } from '../Modal'

type Office = {
  id: string
  name: string
}

type FullOffice = {
  id: string
  name: string
  address: {
    zipcode: string
    street: string
    number: string
    complement: string | null
    cityId: string
    stateId: string
  }
}

type UseOfficesResult = {
  offices: Office[]
}

export function useOffices(
  options?: UseQueryOptions<UseOfficesResult, AxiosError>
): UseQueryResult<UseOfficesResult, AxiosError> {
  return useQuery<UseOfficesResult, AxiosError>(
    ['me-offices'],
    async () => {
      const { data } = await api.get<Office[]>(`/lawyer/profile/offices`)

      return { offices: data }
    },
    {
      staleTime: 1000 * 60 * 1, // One minutes
      ...options
    }
  )
}

const officeFormSchema = yup.object({
  name: yup.string().required('O nome é obrigatório'),
  address: yup.object({
    zipcode: yup.string().required('O CEP é obrigatório.'),
    street: yup.string().required('A rua é obrigatória.'),
    number: yup.string().required('O número é obrigatório.'),
    complement: yup.string(),
    cityId: yup.string().required('A cidade é obrigatória.'),
    stateId: yup.string().required('O estado é obrigatório.')
  })
})

export function Offices(): JSX.Element {
  const { data, isLoading, error, isFetching, refetch } = useOffices()
  const [isOpen, setIsOpen] = useState(false)
  const formRef = useRef<FormHandles>(null)
  const [updatingOffice, setUpdatingOffice] = useState<FullOffice | undefined>(
    undefined
  )
  const can = useCan('offices', data?.offices)
  const [cities, setCities] = useState<Option[]>([])

  const { data: states } = useStates()
  const [deletingOffice, setDeletingOffice] = useState('')
  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: 'top-right'
  })

  const handleSubmit: SubmitHandler<FullOffice> = async data => {
    try {
      await officeFormSchema.validate(data, {
        abortEarly: false
      })
      if (updatingOffice) {
        await api.put(`/lawyer/profile/offices/${updatingOffice.id}`, {
          ...data,
          address: {
            ...data.address,
            complement: data.address.complement || null
          }
        })
        setUpdatingOffice(undefined)
        toast({
          status: 'success',
          description: 'Escritório atualizado com sucesso!'
        })
      } else {
        await api.post(`/lawyer/profile/offices`, {
          ...data,
          address: {
            ...data.address,
            complement: data.address.complement || null
          }
        })
        setIsOpen(false)
        toast({
          status: 'success',
          description: 'Escritório criado com sucesso!'
        })
      }

      refetch()
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        formRef.current?.setErrors(formatYupError(error))
      } else {
        toast({
          status: 'error',
          description:
            'Ocorreu um problema ao tentar criar ou atualizar um escritório.'
        })
      }
    }
  }

  async function handleSelectState(
    event: ChangeEvent<HTMLSelectElement>
  ): Promise<void> {
    try {
      const id = event.target.value
      if (id) {
        const { cities } = await getCities(id)
        setCities(cities)
      } else {
        setCities([])
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast({
          status: 'error',
          description: formatErrorMessage(error)
        })
      }
    }
  }

  async function handleDeleteOffice(): Promise<void> {
    try {
      await api.delete(`/lawyer/profile/offices/${deletingOffice}`)
      refetch()
      setDeletingOffice('')
      toast({
        status: 'success',
        description: 'Escritório removido com sucesso!'
      })
    } catch (error) {
      toast({
        status: 'error',
        description: 'Ocorreu um erro ao tentar excluir o escritório'
      })
    }
  }

  async function handleOpenUpdate(id: string): Promise<void> {
    const { data } = await api.get<FullOffice>(
      `/lawyer/profile/offices/detail/${id}`
    )
    const { cities } = await getCities(data.address.stateId)
    setCities(cities)
    setUpdatingOffice(data)
  }

  function handleCloseModal(): void {
    if (isOpen) setIsOpen(false)
    else setUpdatingOffice(undefined)
    setCities([])
  }

  function handleOpenModal(): void {
    if (can) {
      setIsOpen(true)
    } else {
      toast({
        status: 'warning',
        title: 'Você não tem permissão!',
        description: 'Seu plano limita a criação de escritórios.'
      })
    }
  }

  async function handleCepChange(
    event: ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    const cep = event.target.value
    if (cep.length === 9) {
      try {
        const res = await getAddressFromCEP(cep)
        const formData = formRef.current?.getData()
        const stateId = states?.states.find(
          state => state.label === UFs[res.state]
        )?.value

        const { cities } = await getCities(String(stateId))
        setCities(cities)

        formRef.current?.setData({
          ...formData,
          address: {
            ...formData?.address,
            street: res.neighborhood
              ? res.neighborhood
              : formData?.address.street,
            stateId,
            cityId: cities?.find(city => city.label === res.city)?.value
          }
        })
      } catch (error) {
        if (error instanceof GetAddressFromCEPError) {
          toast({ status: 'warning', description: error.message })
        }
      }
    }
  }

  return (
    <>
      <ContentWrapper
        isLoading={!isLoading && isFetching}
        w="full"
        title="Escritórios"
        infoLabel="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        headerChildren={
          <Button variant="link" color="green.300" onClick={handleOpenModal}>
            Adicionar escritório
          </Button>
        }
      >
        <VStack align="flex-start" divider={<StackDivider />}>
          {error ? (
            <Text color="red.400" fontWeight="medium">
              Falha ao tentar buscar os seus escritórios
            </Text>
          ) : isLoading ? (
            <Stack direction={['column', 'column', 'row']} spacing="2" w="full">
              <Icon as={IoLocation} mt="1" w="6" h="6" color="green.300" />
              <Stack
                w="full"
                direction={['column', 'column', 'row']}
                justify="space-between"
              >
                <Skeleton w="350px" h="32px" />
                <Flex minW="90">
                  <Skeleton w="40px" h="40px" mr="3" />
                  <Skeleton w="40px" h="40px" />
                </Flex>
              </Stack>
            </Stack>
          ) : data?.offices.length === 0 ? (
            <Text fontWeight="medium">
              Você não possui escritórios cadastrados.
            </Text>
          ) : (
            data?.offices.map(office => (
              <Stack
                direction={['column', 'column', 'row']}
                spacing="2"
                w="full"
                key={office.id}
              >
                <Icon as={IoLocation} mt="1" w="6" h="6" color="green.300" />
                <Stack
                  w="full"
                  direction={['column', 'column', 'row']}
                  justify="space-between"
                >
                  <Text fontSize="lg" maxW="350px">
                    {office.name}
                  </Text>
                  <Box minW="90">
                    <IconButton
                      mr="2"
                      variant="unstyled"
                      fontSize="20"
                      color="blue.300"
                      _hover={{ backgroundColor: 'gray.100' }}
                      icon={<Icon as={MdEdit} />}
                      aria-label={`Excluir ${office.name}`}
                      onClick={() => handleOpenUpdate(office.id)}
                    />
                    <IconButton
                      variant="unstyled"
                      fontSize="20"
                      color="red.400"
                      _hover={{ backgroundColor: 'gray.100' }}
                      icon={<Icon as={MdDelete} />}
                      aria-label={`Excluir ${office.name}`}
                      onClick={() => setDeletingOffice(office.id)}
                    />
                  </Box>
                </Stack>
              </Stack>
            ))
          )}
        </VStack>
      </ContentWrapper>
      <Modal
        title="Escritório"
        isOpen={isOpen || !!updatingOffice}
        onClose={handleCloseModal}
      >
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={updatingOffice}
          d="flex"
          flexDirection="column"
          alignItems="flex-end"
          gridGap="4"
        >
          <Input
            name="name"
            label="Nome do escritório"
            placeholder="Informe o nome do escritório"
          />
          <Scope path="address">
            <Input
              name="zipcode"
              label="CEP"
              mask="cep"
              placeholder="Informe o CEP"
              onChange={handleCepChange}
            />
            <Input name="street" label="Rua" placeholder="Informe a rua" />
            <Input
              name="number"
              label="Número"
              placeholder="Informe a número"
            />
            <Input
              name="complement"
              label="Complemento (opcional)"
              placeholder="Informe um complemento"
            />
            <Select
              name="stateId"
              label="Estado"
              options={states?.states || []}
              onChange={handleSelectState}
              placeholder="Selecione um estado"
            />
            <Select
              name="cityId"
              label="Cidade"
              options={cities}
              placeholder="Selecione um cidade"
            />
          </Scope>
          <Button
            colorScheme="yellow"
            onClick={() => formRef.current?.submitForm()}
          >
            {isOpen ? 'Cadastrar' : 'Atualizar'}
          </Button>
        </Form>
      </Modal>
      <ConfirmationModal
        title="Excluir escritório?"
        isOpen={!!deletingOffice}
        onClose={() => setDeletingOffice('')}
        onAccept={handleDeleteOffice}
      />
    </>
  )
}
