import cepPromise from 'cep-promise'

import { UFS } from './validations'

type Address = {
  cep: string
  state: UFS
  city: string
  street: string
  neighborhood: string
}

type Errors = Array<{ message: string; service: string }>

export class GetAddressFromCEPError {
  name = 'CepPromiseError'
  message: string
  type: 'service_error' | 'validation_error'
  errors: Errors
  constructor(
    message: string,
    type: 'service_error' | 'validation_error',
    errors: Errors
  ) {
    this.message = message
    this.type = type
    this.errors = errors
  }
}

export type CepPromiseError = {
  name: 'CepPromiseError'
  message: string
  type: 'service_error' | 'validation_error'
  errors: Array<{ message: string; service: string }>
}

export async function getAddressFromCEP(cep: string): Promise<Address> {
  try {
    const address: Address = await cepPromise(cep)
    return {
      cep: address.cep,
      street: address.street,
      neighborhood: address.neighborhood,
      city: address.city,
      state: address.state
    }
  } catch (error) {
    const err = error as CepPromiseError
    return Promise.reject(
      new GetAddressFromCEPError(err.message, err.type, err.errors)
    )
  }
}
