import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { AxiosRequestConfig } from 'axios'

import { api } from '../services/api'

type Me = {
  id: string
  photo: string | null
  title: 'dr.' | 'me.' | 'phd' | 'prof.' | 'esp.' | '' | null
  name: string
  lastName: string
  gender: 'male' | 'female' | null
  oab: string
  publicEmail: string | null
  alternativeMobile1: string | null
  alternativeMobile2: string | null
  whatsapp: string
  whatsappMessage: string | null
  facebook: string | null
  instagram: string | null
  linkedin: string | null
  telegram: string | null
  tiktok: string | null
  twitter: string | null
  website: string | null
  youtube: string | null
  information: string | null
}

export type GetMeResponse = {
  me: Me
}

type UseMeResponse = {
  data?: GetMeResponse
  updateData: Dispatch<SetStateAction<GetMeResponse | undefined>>
  refetch: () => Promise<void>
}

export async function getMe(
  config?: AxiosRequestConfig
): Promise<GetMeResponse> {
  const { data } = await api.get(`/lawyer/profile`, {
    ...config
  })

  return { me: data }
}

export function useMe(): UseMeResponse {
  const [data, setData] = useState<GetMeResponse | undefined>()

  useEffect(() => {
    getMe().then(data => setData(data))
  }, [])

  async function refetch(): Promise<void> {
    const data = await getMe()
    setData(data)
  }

  return { data, updateData: setData, refetch }
}
