import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'

import { AxiosError, AxiosRequestConfig } from 'axios'

import { Option } from '../components/Form/Select'
import { api } from '../services/api'

export type State = {
  id: string
  name: string
  uf: string
}

export type GetStateResponse = {
  states: Option[]
}

export async function getStates(
  config?: AxiosRequestConfig
): Promise<GetStateResponse> {
  const { data } = await api.get<State[]>('/states', {
    ...config
  })

  const formattedStates = data.map(state => ({
    value: state.id,
    label: state.name
  }))

  return { states: formattedStates }
}

export function useStates(
  options?: UseQueryOptions<GetStateResponse, AxiosError>
): UseQueryResult<GetStateResponse, AxiosError> {
  return useQuery<GetStateResponse, AxiosError>(['states'], () => getStates(), {
    staleTime: 1000 * 60 * 30, // Thirty minutes
    ...options
  })
}
