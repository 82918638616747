import { ReactNode } from 'react'

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalProps
} from '@chakra-ui/react'

type CleanModalProps = Omit<ModalProps, 'children'> & {
  children: ReactNode
}

export function CleanModal({
  onClose,
  children,
  ...rest
}: CleanModalProps): JSX.Element {
  return (
    <Modal onClose={onClose} {...rest}>
      <ModalOverlay />
      <ModalContent my="auto" mx="4">
        <ModalBody p={0}>{children}</ModalBody>
      </ModalContent>
    </Modal>
  )
}
