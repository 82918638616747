import { useRef, useState } from 'react'
import { MdDelete, MdBook, MdEdit } from 'react-icons/md'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'

import {
  Box,
  Button,
  Text,
  HStack,
  Icon,
  IconButton,
  ListItem,
  Skeleton,
  Stack,
  StackDivider,
  UnorderedList,
  VStack,
  useToast
} from '@chakra-ui/react'
import { FormHandles, SubmitHandler } from '@unform/core'
import axios, { AxiosError } from 'axios'
import * as yup from 'yup'

import { api } from '../../../services/api'
import { formatYupError } from '../../../utils/formatYupError'
import { ConfirmationModal } from '../../ConfirmationModal'
import { Form } from '../../Form/Form'
import { Input } from '../../Form/Input'
import { Modal } from '../../Modal'

type Course = {
  id: string
  name: string
}

type UseCoursesResult = {
  courses: Course[]
}

type FormData = {
  name: string
}

export function useCourses(
  options?: UseQueryOptions<UseCoursesResult, AxiosError>
): UseQueryResult<UseCoursesResult, AxiosError> {
  return useQuery<UseCoursesResult, AxiosError>(
    ['me-courses'],
    async () => {
      const { data } = await api.get<Course[]>(`/lawyer/profile/languages`)

      return { courses: data }
    },
    {
      staleTime: 1000 * 60 * 1, // One minutes
      ...options
    }
  )
}

export function Courses(): JSX.Element {
  const formRef = useRef<FormHandles>(null)
  const { data, isLoading, error, refetch } = useCourses()
  const [isOpen, setIsOpen] = useState(false)
  const [updatingCourse, setUpdatingCourse] = useState<Course | undefined>(
    undefined
  )
  const [deletingCourse, setDeletingCourse] = useState('')

  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: 'top-right'
  })

  const handleSubmit: SubmitHandler<FormData> = async data => {
    try {
      if (updatingCourse) {
        await api.put(`/lawyer/profile/languages/${updatingCourse.id}`, data)
        setUpdatingCourse(undefined)
        toast({
          status: 'success',
          description: 'Curso de aperfeiçoamento atualizado com sucesso'
        })
      } else {
        await api.post(`/lawyer/profile/languages`, data)
        setIsOpen(false)
        toast({
          status: 'success',
          description: 'Curso de aperfeiçoamento adicionado com sucesso'
        })
      }
      refetch()
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        formRef.current?.setErrors(formatYupError(error))
      } else if (axios.isAxiosError(error)) {
        toast({
          status: 'error',
          description:
            'Erro ao tentar criar ou atualizar o curso de aperfeiçoamento'
        })
      }
    }
  }

  async function handleDeleteCourse(): Promise<void> {
    try {
      await api.delete(`/lawyer/profile/languages/${deletingCourse}`)
      setDeletingCourse('')
      refetch()
      toast({
        status: 'success',
        description: 'Curso de aperfeiçoamento removido com sucesso'
      })
    } catch (error) {
      toast({
        status: 'error',
        description: 'Erro ao tentar excluir o curso de aperfeiçoamento'
      })
    }
  }

  return (
    <>
      <VStack divider={<StackDivider />} mt="4">
        <Stack w="full" direction={['column', 'column', 'row']}>
          <Icon as={MdBook} color="green.300" w="7" h="7" mt="2" />
          <Box w="full">
            <Stack
              w="full"
              justify="space-between"
              direction={['column', 'column', 'row']}
            >
              <Text fontWeight="600" fontSize="xl">
                Cursos de aperfeiçoamento
              </Text>
              <Box>
                <Button
                  variant="link"
                  color="green.300"
                  mr="2"
                  onClick={() => setIsOpen(true)}
                >
                  Adicionar curso
                </Button>
              </Box>
            </Stack>
            <UnorderedList ml="0" mt="3">
              {error ? (
                <Text color="red.400" fontWeight="medium">
                  Falha ao tentar buscar os seus cursos
                </Text>
              ) : isLoading ? (
                <>
                  <ListItem
                    w="full"
                    d="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    gridGap="2"
                  >
                    <Skeleton w="100px" h="32px" />

                    <HStack>
                      <Skeleton w="40px" h="40px" />
                      <Skeleton w="40px" h="40px" />
                    </HStack>
                  </ListItem>
                </>
              ) : data?.courses.length === 0 ? (
                <Text fontWeight="medium">
                  Você não possui cursos de aperfeiçoamento cadastrados.
                </Text>
              ) : (
                data?.courses.map(course => (
                  <ListItem
                    key={course.id}
                    w="full"
                    d="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    gridGap="2"
                  >
                    {course.name}
                    <Box minW="90px">
                      <IconButton
                        mr="2"
                        variant="unstyled"
                        fontSize="20"
                        color="blue.300"
                        _hover={{ backgroundColor: 'gray.100' }}
                        icon={<Icon as={MdEdit} />}
                        aria-label={`Atualizar curso: ${course.name}`}
                        onClick={() => setUpdatingCourse(course)}
                      />
                      <IconButton
                        variant="unstyled"
                        fontSize="20"
                        color="red.400"
                        _hover={{ backgroundColor: 'gray.100' }}
                        icon={<Icon as={MdDelete} />}
                        aria-label={`Excluir curso: ${course.name}`}
                        onClick={() => setDeletingCourse(course.id)}
                      />
                    </Box>
                  </ListItem>
                ))
              )}
            </UnorderedList>
          </Box>
        </Stack>
      </VStack>
      <Modal
        title="Curso de aperfeiçoamento"
        isOpen={isOpen || !!updatingCourse}
        onClose={() =>
          isOpen ? setIsOpen(false) : setUpdatingCourse(undefined)
        }
      >
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={updatingCourse}
          d="flex"
          flexDirection="column"
          alignItems="flex-end"
        >
          <Input
            name="name"
            label="Nome do curso"
            placeholder="Informe o nome do curso"
          />
          <Button
            colorScheme="yellow"
            mt="4"
            onClick={() => formRef.current?.submitForm()}
          >
            {isOpen ? 'Cadastrar' : 'Atualizar'}
          </Button>
        </Form>
      </Modal>
      <ConfirmationModal
        title="Excluir curso de aperfeiçoamento?"
        isOpen={!!deletingCourse}
        onAccept={handleDeleteCourse}
        onClose={() => setDeletingCourse('')}
      />
    </>
  )
}
