import { ReactNode } from 'react'

import {
  Flex,
  Heading,
  Stack,
  Skeleton,
  Text,
  Box,
  Button
} from '@chakra-ui/react'

import { useAuth } from '../hooks/useAuth'
import { PlanSlug } from '../hooks/usePlans'
import { formatPrice } from '../utils/formatPrice'

type PlanData = {
  slug: PlanSlug
  name: string
  price: number | string
  active?: boolean
}

type PlanCardProps = {
  plan?: PlanData
  children?: ReactNode
  isFirst?: boolean | null | undefined
  onClick?: (plan: PlanData) => void
  isHighlight: () => boolean | undefined
}

type PlanDescriptionProps = { title: string; text: string }

export function PlanDescription({
  title,
  text
}: PlanDescriptionProps): JSX.Element {
  return (
    <Box textAlign="center">
      <Heading as="h3" fontSize="lg">
        {title}
      </Heading>
      <Text>{text}</Text>
    </Box>
  )
}

export function PlanCard({
  plan,
  children,
  isHighlight,
  onClick,
  isFirst
}: PlanCardProps): JSX.Element {
  const { user } = useAuth()
  const highlight = isHighlight()

  if (!plan)
    return (
      <Flex
        w="full"
        maxW="300px"
        borderWidth="2px"
        borderColor="gray.100"
        borderRadius="8"
        px="6"
        py="12"
        direction="column"
        align="center"
      >
        <Skeleton w="200px" h="38px" />
        <Stack pt="10" spacing="2" align="center">
          <Skeleton w="220px" h="22px" />
          <Skeleton w="180px" h="16px" />
          <Skeleton w="160px" h="16px" />
        </Stack>
        <Stack mt="6" spacing="2" align="center">
          <Skeleton w="220px" h="22px" />
          <Skeleton w="180px" h="16px" />
          <Skeleton w="160px" h="16px" />
          <Skeleton w="160px" h="16px" />
        </Stack>

        <Skeleton w="200px" h="46px" mt="auto" />
      </Flex>
    )

  return (
    <Flex
      w="full"
      maxW="300px"
      borderWidth={plan?.slug !== 'gold' ? '2px' : 'none'}
      borderColor={plan?.slug !== 'gold' ? 'gray.100' : 'none'}
      boxShadow={plan?.slug === 'gold' ? 'md' : 'none'}
      borderRadius="8"
      px="6"
      py="12"
      direction="column"
      align="center"
    >
      <Heading as="h2" textAlign="center" mb="6">
        {plan?.name}
      </Heading>
      {children}
      <Text
        fontSize="3xl"
        fontWeight="bold"
        mb="32px"
        mt="auto"
        color={plan.slug === 'gold' ? 'green.300' : 'gray.500'}
      >
        {plan.slug !== 'free' ? (
          <>
            {formatPrice(plan.price)}
            <Text as="span" fontSize="xl">
              /mês
            </Text>
          </>
        ) : (
          'Gratuito'
        )}
      </Text>
      <Button
        colorScheme={highlight ? 'yellow' : 'gray'}
        w="full"
        h="48px"
        maxW="200px"
        isDisabled={!plan.active && user?.plan !== plan.slug}
        onClick={() => onClick && plan && onClick(plan)}
      >
        {isFirst
          ? 'Escolher plano'
          : user?.plan === plan.slug
          ? 'Plano atual'
          : plan.active
          ? 'Escolher plano'
          : 'Indisponível'}
      </Button>
    </Flex>
  )
}
